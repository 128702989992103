import {
  beginAjaxCall,
  beginAjaxCallSuccess,
  ajaxCallError,
} from "./ajaxStatusActions";
import * as types from "../constants/userConstant";
import { getCaseAPI, getCaseDataByEmailAPI } from "../api/caseAPI";
import { HEARINGS_BY_NEUTRAL, HEARINGS } from "../api/baseURL";
import * as Utility from "../utils/utility";
import { decryptItem } from "../shared/Encrypt";

export function caseSuccessDispatch(reesponse) {
  return {
    type: types.CASE_SUCCESS,
    reesponse,
  };
}
export function getCase() {
  const url = Utility.IsHearingOfficer()
    ? `${HEARINGS_BY_NEUTRAL}?neutralEmail=${decryptItem("neutralsEmail")}`
    : HEARINGS;
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return getCaseAPI(url)
      .then((responseModel) => {
        dispatch(beginAjaxCallSuccess());
        dispatch(caseSuccessDispatch(responseModel.data));
        return responseModel.data;
      })
      .catch((error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error.response);
      });
  };
}
export function getCaseByEmail(data) {
  const url = Utility.IsHearingOfficer() ? HEARINGS_BY_NEUTRAL : HEARINGS;
  return function (dispatch) {
    dispatch(beginAjaxCall());
    return getCaseDataByEmailAPI(url, data)
      .then((responseModel) => {
        dispatch(beginAjaxCallSuccess());
        dispatch(caseSuccessDispatch(responseModel.data));
        return responseModel.data;
      })
      .catch((error) => {
        dispatch(ajaxCallError());
        return Promise.reject(error.response);
      });
  };
}

export function setSelectedCaseForDecisionApply(caseData) {
  return {
    type: types.SET_SELECTED_CASE_FOR_DECISION_APPLY,
    selectedCaseForDecisionApply: caseData,
  };
}

export function setSelectedMassFileHearing(hearing) {
  return {
    type: types.SET_SELECTED_MASS_FILE_HEARING,
    selectedMassFileHearing: hearing,
  };
}
