import React, { useState } from "react";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
import { Paginator } from "../../shared/paginator";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const ApplyDecisionTemplateCases = (props) => {
  const [data, setData] = useState(
    decryptItem("massFileCasesForDecisionTemplate") ?? []
  );
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const totalRecords = data.length;

  const onChildMount = (dataFromChild) => {
    console.log("child mount");
  };

  const handleSelectChange = (e) => {
    const size = e.target.value;
    setRecordPerPage(+size);
    setPageNumber(1);
  };

  const onChangePage = (e, page) => {
    setPageNumber(page + 1);
  };

  const paginateData = () => {
    // Calculate start index based on current page
    const startIndex = (pageNumber - 1) * recordPerPage;

    // Slice the array to get the items for the current page
    const paginatedData = data.slice(startIndex, startIndex + recordPerPage);

    return paginatedData;
  };

  const onSelectionChange = (index, checked) => {
    const updatedData = [...data];

    updatedData[index].isSelected = checked;

    setData([...updatedData]);
    encryptItem("massFileCasesForDecisionTemplate", [...updatedData]);
    props.onSelectionChange(updatedData.filter((x) => x.isSelected === true));
  };

  const toggleSelectAll = (selected) => {
    const updatedData = [...data];

    updatedData.forEach((x) => {
      if (!(x.isDecisionExist === true)) {
        x.isSelected = selected;
      }
    });

    setData([...updatedData]);
    encryptItem("massFileCasesForDecisionTemplate", [...updatedData]);
    props.onSelectionChange(updatedData.filter((x) => x.isSelected === true));
  };

  const isAllSelected = data.every((x) => x.isSelected === true);

  const pageData = paginateData();

  return (
    <div className="apply-decision-template-cases">
      <div style={{ marginTop: "10px", marginBottom: "20px" }}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    disabled={data.every((x) => x.isDecisionExist === true)}
                    checked={isAllSelected}
                    onChange={({ target }) => toggleSelectAll(target.checked)}
                  />
                </TableCell>
                <TableCell>Case ID</TableCell>
                <TableCell align="right">Case Caption</TableCell>
                <TableCell align="right">Case Group</TableCell>
                <TableCell align="right">Case Type</TableCell>
                <TableCell align="right">Created On</TableCell>
                <TableCell align="right">Case Status</TableCell>
                <TableCell align="right">AE / CM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageData.map((row, index) => (
                <TableRow key={row.caseId}>
                  <TableCell component="th">
                    <Checkbox
                      disabled={row.isDecisionExist === true}
                      checked={
                        row.isSelected === true || row.isDecisionExist === true
                      }
                      onChange={({ target }) =>
                        onSelectionChange(index, target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell component="th">{row.caseId}</TableCell>
                  <TableCell align="right">{row.caseCaption}</TableCell>
                  <TableCell align="right">{row.caseGroup}</TableCell>
                  <TableCell align="right">{row.caseType}</TableCell>
                  <TableCell align="right">{row.createdOn}</TableCell>
                  <TableCell align="right">{row.caseStatus}</TableCell>
                  <TableCell align="right">{row.aeCm}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="page">
        <div className="perPagecounter">
          <p style={{ padding: "6px", backgroundColor: "#e8e8e8" }}>Per Page</p>
          <select
            defaultValue={recordPerPage}
            onChange={(e) => handleSelectChange(e)}
            className="SelectField pageSize"
            name="sortBy"
            id="sortBy"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
        <div className="showingResContainer">
          <p>
            Showing{" "}
            {totalRecords === 0
              ? totalRecords
              : pageNumber > 1
              ? (pageNumber - 1) * recordPerPage + 1
              : pageNumber}{" "}
            -{" "}
            {pageNumber * recordPerPage <= totalRecords
              ? pageNumber * recordPerPage
              : totalRecords}{" "}
            of {totalRecords} records
          </p>
        </div>
        <div className="paginDiv">
          {totalRecords > 0 ? (
            <Paginator
              onMount={onChildMount}
              rowCount={recordPerPage}
              className="pagination"
              totalRecoards={totalRecords}
              recordsPerPage={recordPerPage}
              onPageChange={onChangePage}
              maxPageLimit={5}
              initialPage={pageNumber}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplyDecisionTemplateCases;
