import { post } from "../api/api";
import { DELETE_NEUTRAL_VACATION_API, GET_NEUTRAL_VACATIONS_API, SAVE_NEUTRAL_VACATION_API } from "../api/baseURL";
import { SAVE_DIARY_DATA_API } from "../api/baseURL.m3-Development";
import { DELETE_NEUTRAL_VACATION, GET_NEUTRAL_VACATION, SAVE_DIARY_DATA, SAVE_NEUTRAL_VACATION } from "../constants/userConstant";

export const getNeutralVacationList = (request) => async (dispatch) => {
  try {
    const res = await post(GET_NEUTRAL_VACATIONS_API, request);
    dispatch({
      type: GET_NEUTRAL_VACATION,
      response: res,
    });
  } catch (error) {
    dispatch({
      type: GET_NEUTRAL_VACATION,
      response: { status: 400 },
    });
  }
};

export const saveNeutralVacation = (requestBody) => async (dispatch) => {
  try {
    const res = await post(SAVE_NEUTRAL_VACATION_API, requestBody)
    dispatch({
      type: SAVE_NEUTRAL_VACATION,
      response: res,
    });
  } catch (error) {
    dispatch({
      type: SAVE_NEUTRAL_VACATION,
      response: { status: 400 },
    });
  }
};

export const deleteNeutralVacations = (requestBody) => async (dispatch) => {
  try {
    const res = await post(DELETE_NEUTRAL_VACATION_API, requestBody)
    dispatch({
      type: DELETE_NEUTRAL_VACATION,
      response: res,
    });
  } catch (error) {
    dispatch({
      type: DELETE_NEUTRAL_VACATION,
      response: { status: 400 },
    });
  }
};

export const saveDiaryData = (requestBody) => async (dispatch) => {
  try {
    const res = await post(SAVE_DIARY_DATA_API, requestBody)
    dispatch({
      type: SAVE_DIARY_DATA,
      response: res,
    });
  } catch (error) {
    dispatch({
      type: SAVE_DIARY_DATA,
      response: { status: 400 },
    });
  }
};