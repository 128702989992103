/* eslint-disable jsx-a11y/iframe-has-title */
import {
  HEARINGS_BY_NEUTRAL,
  HEARINGS,
  CASE_SYNC,
  REALTIME_DATA,
} from "../../api/baseURL";
import * as Utility from "../../utils/utility";
import React, { useState, useEffect } from "react";
import MatDatagrid from "f1-mat-data-grid-react";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import "./cases.css";
import "./cases.scss";
import CircleIcon from "@mui/icons-material/Circle";
import Loading from "../../assets/img/loader.gif";
import axios from "axios";
import DatePicker from "react-date-picker";
import { Paginator } from "../../shared/paginator";
import LoadingOverlay from "react-loading-overlay";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
import { setSelectedMassFileHearing } from "../../actions/caseAction";
import { useDispatch } from "react-redux";

const Cases = (props) => {
  const history = props.history;
  const dispatch = useDispatch();
  const isLoaded = useState(true);
  const [displayProducts, setdisplayProducts] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [pageNumber, setpageNumber] = useState(1);
  const [gridfilter, setgridfilter] = useState([]);
  const [recordPerPage, setrecordPerPage] = useState(20);
  const [filterByCase, setfilterByCase] = useState(
    localStorage.getItem("FilterByCases")
      ? localStorage.getItem("FilterByCases")
      : "Next1month"
  );
  const [filterByCasesData, setfilterByCasesData] = useState(
    localStorage.getItem("FilterOncases")
      ? localStorage.getItem("FilterOncases")
      : "Activecases"
  );
  const [filterByCaseDate, setfilterByCaseDate] = useState("");
  const [filterByCaseDateEQ, setfilterByCaseDateEQ] = useState("");
  const [orderDirection, setorderDirection] = useState(
    localStorage.getItem("orderDirection")
      ? localStorage.getItem("orderDirection")
      : localStorage.getItem("FilterByCases") === "Customdate" ||
        localStorage.getItem("FilterByCases") === "Next1month" ||
        !localStorage.getItem("FilterByCases")
      ? "asc"
      : "asc"
  );
  const [orderBy, setorderBy] = useState("HearingDateISO");
  const [orderByColumn, setorderByColumn] = useState(2);
  const [datechange, setdatechange] = useState("");
  const [fromdate, setFromdate] = useState("");
  const [todate, setTodate] = useState("");
  const [timechange, settimechange] = useState(null);
  const [durationchange, setdurationchange] = useState(null);
  const [namIdchange, setnamIdchange] = useState(null);
  const [captionchange, setcaptionchange] = useState(null);
  const [neutralNamechange, setneutralNamechange] = useState(null);
  const [statusChange, setstatusChange] = useState(null);
  const [isActive, setisActive] = useState(true);
  const [disableFilter, setdisableFilter] = useState(true);
  const [caseFilter, setcaseFilter] = useState(
    !!JSON.parse(localStorage.getItem("caseFilter"))
      ? JSON.parse(localStorage.getItem("caseFilter"))
      : [
          {
            filterName: "HearingDateISO",
            filterValue: new Date(
              new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).setHours(
                23,
                59,
                59,
                99
              )
            ),
            filterType: "compare",
            filterEq: "<=",
          },
        ]
  );
  const [caseStatusList, setCaseStatusList] = useState({});
  const [isLoading, SetIsloading] = useState(false);

  const handleClick = (e, rowData, history) => {
    setisActive(true);
    e.preventDefault();

    //// TODO: REMOVE THIS STATIC DATA
    const updatedRowData = {
      ...rowData,
      ////massFileId: "de86cab9-26d7-4c45-b421-daee272d17b1",
      ////groupId: "f516a167-ad40-4de9-b91e-f168a0a18b2c",
      ////subGroupId: "a29b8551-647c-4962-8ea3-4e0c731a6147",
    };

    caseSync(updatedRowData, history);
    dispatch(setSelectedMassFileHearing(updatedRowData));
  };

  const caseSync = (rowData, history) => {
    const PMASyncbody = {
      caseCode: rowData.caseCode,
    };

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const { massFileId } = rowData;
    const isHearingForMassFile = massFileId && massFileId !== "";

    if (REALTIME_DATA) {
      let flag = false;
      axios.post(CASE_SYNC, PMASyncbody, config).then((res2) => {
        if (res2.status === 200) {
          encryptItem(
            "hearingDetails",
            JSON.stringify({
              hearingCode: rowData.hearingCode,
              caseType: rowData.caseType,
              goBack: "cases",
              pendingAt: isHearingForMassFile
                ? "caseInfo"
                : "schedulingInformation",
              cotinuedFrom: rowData.cotinuedFrom,
              rowData,
            })
          );
          const url = `/caseDetails`;
          history.push(url);
          flag = true;
          console.log("case data synced");
        }
      });
      setTimeout(function () {
        // In the timer function that gets executed after 5 seconds, check // the flag value. If we got the response already it would have been // set to true. Otherwise false
        if (!flag) {
          encryptItem(
            "hearingDetails",
            JSON.stringify({
              hearingCode: rowData.hearingCode,
              caseType: rowData.caseType,
              goBack: "cases",
              pendingAt: isHearingForMassFile
                ? "caseInfo"
                : "schedulingInformation",
              rowData,
            })
          );
          const url = `/caseDetails`;
          history.push(url);
        }
      }, 15000);
    } else {
      encryptItem(
        "hearingDetails",
        JSON.stringify({
          hearingCode: rowData.hearingCode,
          caseType: rowData.caseType,
          goBack: "cases",
          pendingAt: isHearingForMassFile
            ? "caseInfo"
            : "schedulingInformation",
          rowData,
        })
      );
      const url = `/caseDetails`;
      history.push(url);
    }
  };

  const columns = Utility.IsHearingOfficer()
    ? [
        {
          title: "NAM ID",
          field: "caseReference",
          width: "80px",
          sorting: true,
          render: (rowData) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {getcasecolorcode(
                rowData.caseReference,
                rowData.caseType,
                rowData.isCommercialCase,
                rowData
              )}
            </div>
          ),
        },
        {
          title: "Case Caption",
          field: "caseName",
          width: "280px",
          sorting: true,
          render: (rowData) => (
            <div>
              <Link
                onClick={(e) => {
                  handleClick(e, rowData, history);
                }}
                to={`/caseDetails`}
                //to={generatePath(`/caseDetails/:hearingCode/:caseType/cases/schedulingInformation`,rowData.hearingCode,rowData.caseType)}
                style={{ color: "#145187", textDecoration: "underline" }}
              >
                <div
                  className="tooltip1"
                  style={{ textAlign: "left" }}
                >
                  {rowData.casetext}
                  <span
                    className="tooltiptext"
                    style={{ textAlign: "left", paddingLeft: "2px" }}
                  >
                    {rowData.caseName}
                  </span>
                  <br></br>
                </div>
              </Link>
              <br></br>
              {rowData.cotinuedFrom !== "-" && (
                <span
                  className="tooltiptext"
                  style={{
                    borderRadius: "5px",
                    color: "white",
                    backgroundColor: "#D45F5F",
                    padding: "1px 5px 1px 5px",
                    fontWeight: "500",
                    textWrap: "nowrap",
                  }}
                >
                  Continuance{" "}
                  {rowData.caseContinuedCaseReference &&
                    rowData.caseContinuedCaseReference + " "}
                  {Utility.getdate(rowData.cotinuedFrom)}
                </span>
              )}
            </div>
          ),
        },
        {
          title: "Date",
          field: "hearingDate",
          render: (rowData) => Utility.getdate(rowData.hearingDate),
          sorting: true,
          type: "date",
          width: "80px",
          className: "case-hearingdate",
        },
        {
          title: "Time",
          field: "hearingStartTimeUTCFromCMS",
          render: (rowData) =>
            rowData["hearingStartTimeUTCFromCMS"] !== undefined ||
            rowData["hearingStartTimeUTCFromCMS"] !== null ||
            rowData["hearingStartTimeUTCFromCMS"] !== ""
              ? new Date(
                  Utility.convertUTCDateToUserTimeZone(
                    new Date(
                      rowData["hearingStartTimeUTCFromCMS"]
                    ).toUTCString()
                  )
                )
                  .toLocaleTimeString()
                  .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
              : //rowData["hearingStartTimeUTCFromCMS"]
                null,
          sorting: true,
          width: "10px",
        },

        {
          title: "Duration",
          field: "hearingDuration",
          width: "80px",
          sorting: true,
          render: (rowData) => <div>{rowData["hearingDuration"]} hrs</div>,
        },
        {
          title: "Status",
          field: "hearingStatus",
          sorting: true,
          width: "80px",
          type: "select",
          lookup: caseStatusList,
          // {
          //   //ADJOURNED: "ADJOURNED",
          //   //CANCELLED: "CANCELLED",
          //   //CLOSED: "CLOSED",
          // },
        },
        // ,
        // {
        //   title: "Continued From",
        //   width: '80px',
        //   field: "cotinuedFrom",
        //   sorting: false,
        //   render: (rowData) => rowData.cotinuedFrom==="-"?rowData.cotinuedFrom : Utility.getdate(rowData.cotinuedFrom),
        // },
      ]
    : [
        {
          title: "NAM ID",
          field: "caseReference",
          width: "80px",
          sorting: true,
          render: (rowData) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              {getcasecolorcode(
                rowData.caseReference,
                rowData.caseType,
                rowData.isCommercialCase,
                rowData
              )}
            </div>
          ),
        },
        {
          title: "Case Caption",
          field: "caseName",
          width: "280px",
          sorting: true,
          render: (rowData) => (
            <div>
              <Link
                onClick={(e) => {
                  handleClick(e, rowData, history);
                }}
                to={`/caseDetails`}

                //    to={`/caseDetails/${rowData.hearingCode}/${rowData.caseType}/cases/schedulingInformation`}
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
              >
                <div
                  className="tooltip1"
                  style={{ textAlign: "left" }}
                >
                  {rowData.casetext}
                  <span
                    className="tooltiptext"
                    style={{ textAlign: "left", paddingLeft: "2px" }}
                  >
                    {rowData.caseName}
                  </span>
                </div>
              </Link>
              <br></br>
              {rowData.cotinuedFrom !== "-" && (
                <span
                  className="tooltiptext"
                  style={{
                    borderRadius: "5px",
                    color: "white",
                    backgroundColor: "#D45F5F",
                    padding: "1px 5px 1px 5px",
                    fontWeight: "500",
                    textWrap: "nowrap",
                  }}
                >
                  Continuance{" "}
                  {rowData.caseContinuedCaseReference &&
                    rowData.caseContinuedCaseReference + " "}
                  {Utility.getdate(rowData.cotinuedFrom)}
                </span>
              )}
            </div>
          ),
        },
        {
          title: "Date",
          field: "hearingDate",
          width: "80px",
          type: "date",
          render: (rowData) => Utility.getdate(rowData.hearingDate),
          sorting: true,
        },
        {
          title: "Time",
          field: "hearingStartTimeUTCFromCMS",
          width: "20px",
          render: (rowData) =>
            rowData["hearingStartTimeUTCFromCMS"] !== undefined ||
            rowData["hearingStartTimeUTCFromCMS"] !== null ||
            rowData["hearingStartTimeUTCFromCMS"] !== ""
              ? new Date(
                  Utility.convertUTCDateToUserTimeZone(
                    new Date(
                      rowData["hearingStartTimeUTCFromCMS"]
                    ).toUTCString()
                  )
                )
                  .toLocaleTimeString()
                  .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
              : //rowData["hearingStartTimeUTCFromCMS"]
                null,
          sorting: true,
        },

        {
          title: "Duration",
          width: "10px",
          field: "hearingDuration",
          sorting: true,
          render: (rowData) => <div>{rowData["hearingDuration"]} hrs</div>,
        },
        {
          title: "Hearing Officer",
          width: "80px",
          field: "hearingOfficerName",
          sorting: true,
          render: (rowData) => <div>{rowData["hearingOfficerName"]}</div>,
        },
        {
          title: "Status",
          field: "hearingStatus",
          width: "20px",
          sorting: true,
          type: "select",
          lookup: caseStatusList,
        },
        // {
        //   title: "Continued From",
        //   width: '80px',
        //   field: "cotinuedFrom",
        //   sorting: false,
        //   render: (rowData) => rowData.cotinuedFrom==="-"?rowData.cotinuedFrom : Utility.getdate(rowData.cotinuedFrom),
        // }
      ];
  const colmn = Utility.IsHearingOfficer() ? "colmn6" : "col-md-3 col-6";
  useEffect(() => {
    getCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, recordPerPage, gridfilter, orderDirection, orderBy]);

  const onChangePage = (e, page) => {
    setpageNumber(page + 1);
  };

  const handleSelectChange = (e) => {
    const size = e.target.value;
    setrecordPerPage(+size);
    setpageNumber(1);
  };
  const onFilterChange = (event) => {
    console.log("filterChange");
  };

  const getObject = () => {
    const setdefaultFilter =
      caseFilter.length > 0
        ? caseFilter
        : caseFilter.length > 0 && gridfilter.length > 0
        ? onFilterByCases(null, true)
        : gridfilter;
    const requestObject = {
      PageNumber: pageNumber,
      PageSize: recordPerPage,
      Sort:
        orderBy !== undefined && orderBy !== ""
          ? orderBy.charAt(0).toUpperCase() + orderBy.slice(1)
          : orderBy,
      SortDirection: orderDirection, //caseFilter.length === 0 && gridfilter.length === 0 ? "desc" : ((localStorage.getItem("FilterByCases")).indexOf("Next1")!==-1?"desc": orderDirection),
      Filters:
        caseFilter.length > 0
          ? caseFilter
          : caseFilter.length === 0 && gridfilter.length === 0
          ? [
              {
                filterName: "HearingDateISO",
                filterValue: new Date(
                  new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).setHours(
                    23,
                    59,
                    59,
                    99
                  )
                ),
                filterType: "compare",
                filterEq: "<=",
              },
            ]
          : setdefaultFilter,
      NeutralEmail: decryptItem("neutralsEmail"),
      UserCode: decryptItem("jCode"),
      UserType: decryptItem("roleId"),
      CaseType: filterByCasesData,
    };
    return requestObject;
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getFilterObjectfromSession = (filterArr) => {
    let name = "",
      filter = "";
    for (let i = 0; i < filterArr.length; i++) {
      filter = filterArr[i].filterValue;
      if (filterArr[i].filterName === "HearingDate") {
        name = "date";
        setdatechange(filterArr[i].filterValue);
      }

      if (filterArr[i].filterName === "HearingTime") {
        name = "time";
        settimechange(filterArr[i].filterValue);
      }
      if (filterArr[i].filterName === "HearingDuration") {
        name = "duration";
        setdurationchange(filterArr[i].filterValue);
      }
      if (filterArr[i].filterName === "CaseReference") {
        name = "namId";
        setnamIdchange(filterArr[i].filterValue);
      }
      if (filterArr[i].filterName === "CaseName") {
        name = "caption";
        setcaptionchange(filterArr[i].filterValue);
      }
      if (filterArr[i].filterName === "HearingOfficerName") {
        name = "neutralname";
        setneutralNamechange(filterArr[i].filterValue);
      }

      if (filterArr[i].filterName === "HearingStatus") {
        name = "status";
        setstatusChange(filterArr[i].filterValue);
      }
    }

    checkFilterApplicable(name, filter);
  };
  const getCases = () => {
    setisActive(true);
    getFilterObjectfromSession(caseFilter);
    const url = Utility.IsHearingOfficer() ? HEARINGS_BY_NEUTRAL : HEARINGS;
    axios
      .post(url, getObject(), {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        const element = document.getElementsByClassName("caseTable");

        if (res.status === 200) {
          element[0].classList.remove("no-records");
          //// console.log("Order by", orderByColumn, orderDirection, typeof (orderByColumn));
          setisActive(false);
          SetIsloading(false);
          if (res.data && res.data?.data) {
            if (res.data?.data.length > 0) {
              for (let i = 0; i < res.data.data.length; i++) {
                res.data.data[i].hearingStatus =
                  res.data.data[i].hearingStatus.toUpperCase();
                const caseName = res.data.data[i].caseName;
                res.data.data[i].casetext =
                  caseName.length > 0 ? caseName.substring(0, 100) : "";
              }
              const statusList = {};
              res.data.hearingStatus.forEach((hearingElement) => {
                statusList[hearingElement] = hearingElement;
              });
              setCaseStatusList(statusList);
              setdisplayProducts(res.data.data);
              settotalRecords(res.data.totalCount ? res.data.totalCount : 0);
              if (pageNumber !== res.data.pageNumber) {
                setpageNumber(res.data.pageNumber ? res.data.pageNumber : 0);
              }
            } else {
              setdisplayProducts([]);
              settotalRecords(0);
              element[0].classList.add("no-records");
            }
          } else {
            setdisplayProducts([]);
            settotalRecords(0);
            element[0].classList.add("no-records");
          }
        }
      })
      .catch((error) => {
        setisActive(false);
        SetIsloading(false);
        return Promise.reject(error.response);
      });
  };

  const onFilterByCases = (e, defaultfilter = false) => {
    setfilterByCase(defaultfilter ? "Next1month" : e.target.value);
    let filterByCaseDateEQlocal = "";
    let filterByCaseDatelocal = "";
    const filterValue = defaultfilter ? "Next1month" : e.target.value;
    //localStorage.setItem("FilterByCases",e.target.value);
    if (filterValue === "Today") {
      filterByCaseDateEQlocal = ">=";
      filterByCaseDatelocal = new Date(Date.now()).setHours(0, 0, 0, 0);
    } else if (filterValue === "Previous1week") {
      filterByCaseDateEQlocal = ">=";
      filterByCaseDatelocal = new Date(
        Date.now() - 7 * 24 * 60 * 60 * 1000
      ).setHours(23, 59, 59, 99);
    } else if (filterValue === "Previous1month") {
      filterByCaseDateEQlocal = ">=";
      filterByCaseDatelocal = new Date(
        Date.now() - 30 * 24 * 60 * 60 * 1000
      ).setHours(23, 59, 59, 99);
    } else if (filterValue === "Previous6months") {
      filterByCaseDateEQlocal = ">=";
      filterByCaseDatelocal = new Date(
        Date.now() - 180 * 24 * 60 * 60 * 1000
      ).setHours(23, 59, 59, 99);
    } else if (filterValue === "Next1week") {
      filterByCaseDateEQlocal = "<=";
      filterByCaseDatelocal = new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).setHours(23, 59, 59, 99);
    } else if (filterValue === "Next1month") {
      filterByCaseDateEQlocal = "<=";
      filterByCaseDatelocal = new Date(
        Date.now() + 30 * 24 * 60 * 60 * 1000
      ).setHours(23, 59, 59, 99);
    } else if (filterValue === "Next6months") {
      filterByCaseDateEQlocal = "<=";
      filterByCaseDatelocal = new Date(
        Date.now() + 180 * 24 * 60 * 60 * 1000
      ).setHours(23, 59, 59, 99);
    }

    if (filterValue === "Customdate") {
      setdisableFilter(true);
    } else {
      setfilterByCaseDateEQ(filterByCaseDateEQlocal);
      setfilterByCaseDate(
        filterValue === "Today"
          ? new Date().toJSON().slice(0, 10)
          : new Date(filterByCaseDatelocal)
      );
      checkFilterApplicable("filterbycase", filterValue);
      setdisableFilter(false);
    }
    setTodate("");
    setFromdate("");
  };
  const onFilterByCasesData = (e) => {
    setfilterByCasesData(e.target.value);
    const casefilterValue = e.target.value;
    checkFilterApplicable("filterbycasedata", casefilterValue);
    setdisableFilter(false);
    // localStorage.setItem("FilterOncases",e.target.value);
    // localStorage.setItem("FilterByCases",e.target.value);
  };
  const getcasecolorcode = (casecode, casetype, isCommercialCase, rowData) => {
    const { massFileId, subGroupId } = rowData;
    //// const massFileId = "234-234-234";
    //// const subGroupId = "2349-987345";
    const isHearingForMassFile = massFileId && massFileId !== "";

    let content = null;

    if (isHearingForMassFile) {
      content = (
        <div style={{ marginRight: "5px" }}>{renderMassFileIcon(false)}</div>
      );
    } else {
      if (!isCommercialCase) {
        content = (
          <div
            style={{ marginRight: "5px" }}
            className={
              casetype.trim().toLowerCase().includes("mediation")
                ? "circleBase hold"
                : "circleBase inprogress"
            }
          ></div>
        );
      } else {
        if (isCommercialCase && !Utility.IsHearingOfficer()) {
          content = (
            <div style={{ marginRight: "5px" }}>
              <span>
                <i className="ccircle">C</i>
              </span>
            </div>
          );
        } else {
          content = (
            <div
              style={{ marginRight: "5px" }}
              className={
                casetype.trim().toLowerCase().includes("mediation")
                  ? "circleBase hold"
                  : "circleBase inprogress"
              }
            ></div>
          );
        }
      }
    }

    return (
      <>
        {content}
        {isHearingForMassFile ? subGroupId : casecode}
      </>
    );
  };

  const onChildMount = (dataFromChild) => {
    console.log("onChildMount");
  };
  const onOrderChange = (column, event) => {
    setorderDirection(event);
    localStorage.setItem("orderDirection", event);
    setorderByColumn(column);
    setorderBy(
      columns[column].field === "hearingDate" ||
        columns[column].field === "hearingTime"
        ? "HearingDateISO"
        : columns[column].field[0].toUpperCase() +
            columns[column].field.substring(1)
    );
  };
  const checkFilterApplicable = (name, value) => {
    const falg = !(
      (name === "date"
        ? value !== undefined && value !== null && value !== ""
        : datechange !== null && datechange !== "") ||
      (name === "date"
        ? value !== undefined && value !== null && value !== ""
        : filterByCaseDate !== null && filterByCaseDate !== "") ||
      (name === "status"
        ? value !== undefined && value !== null && value !== ""
        : statusChange !== null && statusChange !== "") ||
      (name === "time"
        ? value !== undefined && value !== null && value !== ""
        : timechange !== null && timechange !== "") ||
      (name === "duration"
        ? value !== undefined && value !== null && value !== ""
        : durationchange !== null && durationchange !== "") ||
      (name === "namId"
        ? value !== undefined && value !== null && value !== ""
        : namIdchange !== null && namIdchange !== "") ||
      (name === "caption"
        ? value !== undefined && value !== null && value !== ""
        : captionchange !== null && captionchange !== "") ||
      (name === "neutralname"
        ? value !== undefined && value !== null && value !== ""
        : neutralNamechange !== null && neutralNamechange !== "")
    );
    if (filterByCase === "Customdate" && todate && fromdate) {
      setdisableFilter(false);
    } else {
      setdisableFilter(filterByCase === "Customdate" ? true : falg);
    }
  };

  const handleNamIdChange = (e) => {
    let namidval = e.target.validity.valid ? e.target.value : namIdchange;
    if (namidval.length > 10) return;
    setnamIdchange(namidval);
    checkFilterApplicable("namId", namidval);
  };
  const handleCaptionChange = (e) => {
    setcaptionchange(e.target.value);
    checkFilterApplicable("caption", e.target.value);
  };
  const handleneutralNameChange = (e) => {
    setneutralNamechange(e.target.value);
    checkFilterApplicable("neutralname", e.target.value);
  };

  const handleNamIdBlur = (e) => {
    //    createFilterArr("", "")
  };
  const handleCaptionBlur = (e) => {
    //    createFilterArr("", "")
  };
  const handleneutralNameBlur = (e) => {
    //   createFilterArr("", "")
  };
  const handleDateChange = (e) => {
    setdatechange(e);
    checkFilterApplicable("date", e);
    if (e === null) {
      setdisableFilter(false);
    }
  };

  const handleCustomFromDateChange = (e) => {
    setFromdate(e);
    if (e && todate && Utility.getComapreDate(e, todate)) {
      setdisableFilter(false);
    } else {
      //  if(todate && !Utility.getComapreDate(fromdate, e)){
      //     alert("fromdate should be less than todate")
      //   }
      //   else if (!todate) {
      //     alert("todate should not be empty")
      //   }
      setdisableFilter(true);
    }
    //checkFilterApplicable("date", e);
  };
  const handleCustomToDateChange = (e) => {
    setTodate(e);
    if (fromdate && e && Utility.getComapreDate(fromdate, e)) {
      setdisableFilter(false);
    } else {
      // if (fromdate && !Utility.getComapreDate(fromdate, e)) {
      //   alert("todate should be greater than fromdate")
      // }
      // else if (!fromdate) {
      //   alert("fromdate should not be empty")
      // }
      setdisableFilter(true);
    }
  };
  const handleStatusChange = (e) => {
    setstatusChange(e.target.value);
    checkFilterApplicable("status", e.target.value);
  };
  const createFilterArr = (
    datechanged,
    statuschanged,
    filterByCaseDatelocal,
    filterByCaseDateEQlocal
  ) => {
    const arr = [];
    if (filterByCase === "Customdate") {
      const tmObj = {
        filterName: "Customdate",
        filterToDate:
          todate.getFullYear() +
          "-" +
          ("0" + (todate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + todate.getDate()).slice(-2), //todate,
        filterFromDate:
          fromdate.getFullYear() +
          "-" +
          ("0" + (fromdate.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + fromdate.getDate()).slice(-2), //fromdate
        filterType: "Customdate",
      };
      arr.push(tmObj);
      //arr = arr.filter(element=>element.filterName !=="HearingDateISO")
    }
    if (datechange !== null && datechange !== "") {
      const tmObj = {
        filterName: "HearingDate",
        filterValue: datechange,
        filterType: "datetime",
        filterEq: (datechange.getTimezoneOffset() * -1).toString(),
      };
      arr.push(tmObj);
    }

    if (
      filterByCase !== "Customdate" &&
      filterByCaseDate !== null &&
      filterByCaseDate !== ""
    ) {
      const tmObj = {
        filterName: "HearingDateISO",
        filterValue: filterByCaseDate,
        filterType: "compare",
        filterEq: filterByCaseDateEQ,
      };
      arr.push(tmObj);
    }

    if (statusChange !== null && statusChange !== "") {
      const tmObj = {
        filterName: "HearingStatus",
        filterValue: statusChange,
        filterType: "string",
        filterEq: "",
      };
      arr.push(tmObj);
    }
    if (timechange !== null && timechange !== "") {
      const timeValue = timechange;
      const tmObj = {
        filterName: "HearingTime",
        filterValue: timeValue.trim(),
        filterType: "string",
        filterEq: "",
      };
      arr.push(tmObj);
    }
    if (durationchange !== null && durationchange !== "") {
      let durationValue = durationchange;
      if (durationchange <= 0 && durationchange.split(".").length > 1) {
        durationValue = durationchange.split(".")[0];
      }
      const tmObj = {
        filterName: "HearingDuration",
        filterValue: durationValue.trim(),
        filterType: "string",
        filterEq: "",
      };
      arr.push(tmObj);
    }
    if (namIdchange !== null && namIdchange !== "") {
      const tmObj = {
        filterName: "CaseReference",
        filterValue: namIdchange.trim(),
        filterType: "string",
        filterEq: "",
      };
      arr.push(tmObj);
    }
    if (captionchange !== null && captionchange !== "") {
      const tmObj = {
        filterName: "CaseName",
        filterValue: captionchange.trim(),
        filterType: "string",
        filterEq: "",
      };
      arr.push(tmObj);
    }
    if (!Utility.IsHearingOfficer()) {
      if (neutralNamechange !== null && neutralNamechange !== "") {
        const tmObj = {
          filterName: "HearingOfficerName",
          filterValue: neutralNamechange.trim(),
          filterType: "string",
          filterEq: "",
        };
        arr.push(tmObj);
      }
    }

    const filteredArr = buldFilter(arr);
    setgridfilter(filteredArr);
  };
  const buldFilter = (arr) => {
    let tmpFilterArr = [];
    let isCustomFiter = false;
    tmpFilterArr = caseFilter;
    for (const element of arr) {
      //its removed existing object by its filter name
      if (element.filterName === "Customdate") {
        isCustomFiter = true;
        tmpFilterArr = tmpFilterArr.filter(
          (item) => item.filterName !== "Customdate"
        );
        const filterArr = {
          filterName: "Customdate",
          filterToDate:
            todate.getFullYear() +
            "-" +
            ("0" + (todate.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + todate.getDate()).slice(-2), //todate,
          filterFromDate:
            fromdate.getFullYear() +
            "-" +
            ("0" + (fromdate.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + fromdate.getDate()).slice(-2), //fromdate
          filterType: "Customdate",
        };
        if (arr.length === 1) {
          tmpFilterArr = [];
        }
        tmpFilterArr.push(filterArr);
      } else {
        //its removed existing object by its filter name
        tmpFilterArr = tmpFilterArr.filter(
          (item) => item.filterName !== element.filterName
        );
        if (element.filterValue) {
          const filterArr = {
            filterName: element.filterName,
            filterValue:
              typeof element.filterValue === "string"
                ? element.filterValue.trim()
                : element.filterValue,
            filterType: element.filterType,
            filterEq: element.filterEq,
          };
          tmpFilterArr.push(filterArr);
        }
      }
    }
    let filnalFilter = [];
    if (isCustomFiter) {
      tmpFilterArr = tmpFilterArr.filter(
        (item) => item.filterName !== "HearingDateISO"
      );
      //keep Customdate filter;
      let customFilterIndex = tmpFilterArr.findIndex(
        (item) => item.filterName === "Customdate"
      );
      if (customFilterIndex !== -1) {
        filnalFilter.push(tmpFilterArr[customFilterIndex]);
      }
      for (const removeemptyFilters of arr) {
        if (removeemptyFilters.filterName !== "Customdate") {
          //avoid duplication
          let indexOfFIlter = tmpFilterArr.findIndex(
            (item) => item.filterName === removeemptyFilters.filterName
          );
          filnalFilter.push(tmpFilterArr[indexOfFIlter]);
        }
      }
    } else {
      tmpFilterArr = tmpFilterArr.filter(
        (item) => item.filterName !== "Customdate"
      );
      //keep HearingDateISO filter;
      let hearingDateISOFilterIndex = tmpFilterArr.findIndex(
        (item) => item.filterName === "HearingDateISO"
      );
      if (hearingDateISOFilterIndex !== -1) {
        filnalFilter.push(tmpFilterArr[hearingDateISOFilterIndex]);
      }
      for (const removeemptyFilters of arr) {
        if (removeemptyFilters.filterName !== "HearingDateISO") {
          //avoid duplication
          let indexOfFIlter = tmpFilterArr.findIndex(
            (item) => item.filterName === removeemptyFilters.filterName
          );
          filnalFilter.push(tmpFilterArr[indexOfFIlter]);
        }
      }
    }

    setcaseFilter(filnalFilter);
    localStorage.setItem("caseFilter", JSON.stringify(filnalFilter));
    return filnalFilter;
  };
  const applyfilter = () => {
    createFilterArr("", "", "", "");
    setpageNumber(1);
    SetIsloading(true);
    localStorage.setItem("FilterOncases", filterByCasesData);
    localStorage.setItem("FilterByCases", filterByCase);
    //setorderDirection(filterByCase === "Customdate" ? "asc" : "desc");
    setorderDirection(
      localStorage.getItem("orderDirection")
        ? localStorage.getItem("orderDirection")
        : filterByCase === "Customdate"
        ? "asc"
        : "asc"
    );
  };
  const clearfilter = () => {
    setpageNumber(1);
    setfilterByCase("Next1month");
    setdisableFilter(true);
    setdatechange("");
    settimechange("");
    setdurationchange("");
    setnamIdchange("");
    setcaptionchange("");
    setneutralNamechange("");
    setstatusChange("");
    setgridfilter([]);
    setcaseFilter([
      {
        filterName: "HearingDateISO",
        filterValue: new Date(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).setHours(
            23,
            59,
            59,
            99
          )
        ),
        filterType: "compare",
        filterEq: "<=",
      },
    ]);
    localStorage.setItem(
      "caseFilter",
      JSON.stringify([
        {
          filterName: "HearingDateISO",
          filterValue: new Date(
            new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).setHours(
              23,
              59,
              59,
              99
            )
          ),
          filterType: "compare",
          filterEq: "<=",
        },
      ])
    );
    checkFilterApplicable("", "");
    setfilterByCaseDate("");
    setfilterByCaseDateEQ("");
    document.getElementById("clearFilterNamId").value = "";
    setfilterByCasesData("Activecases");
    localStorage.setItem("FilterByCases", "Next1month");
    localStorage.setItem("FilterOncases", "Activecases");
    setorderDirection("asc");
    localStorage.setItem("orderDirection", "asc");

    setTodate("");
    setFromdate("");
  };

  const renderMassFileIcon = (renderText = true) => {
    return (
      <>
        <span>
          <i className="massFileCircle">M</i>
        </span>
        {renderText && <p>Mass Filing</p>}
      </>
    );
  };

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Loading your content..."
    >
      <div
        className="casesContainer caseTable"
        style={{ marginTop: "4.5rem" }}
      >
        {isLoaded ? (
          <div>
            <div className="topBar">
              <div className="leftContainer filterContainer">
                <p
                  style={{
                    marginRight: "15px",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  Filter cases by
                </p>
                <select
                  value={filterByCase}
                  defaultValue={filterByCase}
                  className="SelectField"
                  name="sortBy"
                  id="filterSelect"
                  onChange={(e) => onFilterByCases(e, false)}
                >
                  <option value="All">All</option>
                  <option value="Previous6months">Previous 6 months</option>
                  <option value="Previous1month">Previous 1 month</option>
                  <option value="Previous1week">Previous 1 week</option>
                  <option value="Today">Today</option>
                  <option value="Next1week">Next 1 week</option>
                  <option value="Next1month">Next 1 month</option>
                  <option value="Next6months">Next 6 months</option>
                  <option value="Customdate">Custom date</option>
                </select>
              </div>
              <div className="leftContainer filterContainer">
                <p className="casegridFilter">Show</p>
                <select
                  value={filterByCasesData}
                  defaultValue={filterByCasesData}
                  className="SelectField"
                  name="sortBy"
                  id="filterSelect"
                  onChange={onFilterByCasesData}
                >
                  <option value="Activecases">Active cases</option>
                  <option value="Closedcases">Closed cases</option>
                </select>
              </div>
            </div>
            {/* custome date fields */}
            {filterByCase === "Customdate" && (
              <div className="topBar">
                <div className="leftContainer filterContainer">
                  <p
                    style={{
                      marginRight: "15px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    From date
                  </p>
                  <DatePicker
                    className="customdates"
                    locale="en-US"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    format="dd/MMM/yyyy"
                    showMonthDropdown={false}
                    onChange={handleCustomFromDateChange}
                    value={fromdate}
                    name="fromdate"
                  />
                </div>
                <div className="leftContainer filterContainer">
                  <p className="casegridFilter">To date</p>
                  <DatePicker
                    className="customdates"
                    locale="en-US"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    format="dd/MMM/yyyy"
                    showMonthDropdown={false}
                    onChange={handleCustomToDateChange}
                    name="todate"
                    value={todate}
                  />
                </div>
              </div>
            )}
            {/* End custome date fields */}
            {displayProducts && (
              <div className="dataTable">
                <div className="row">
                  <div className={colmn}>
                    <input
                      type="number"
                      onKeyDown={(event) => {
                        if (event.key == "-") {
                          event.preventDefault();
                        }
                        if (event.key === "Enter") {
                          applyfilter();
                        }
                      }}
                      placeholder="NAM ID"
                      onChange={handleNamIdChange}
                      disabled={isLoading}
                      className="form-control"
                      id="clearFilterNamId"
                      value={namIdchange}
                      min={0}
                      maxLength={10}
                      pattern="[0-9]*"
                      onBlur={handleNamIdBlur}
                    />
                  </div>
                  <div className={colmn}>
                    <input
                      type="text"
                      placeholder="Case Caption"
                      onChange={handleCaptionChange}
                      disabled={isLoading}
                      className="form-control"
                      value={captionchange}
                      onBlur={handleCaptionBlur}
                      maxLength="200"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          applyfilter();
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3 col-6">
                    <DatePicker
                      locale="en-US"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      format="dd/MMM/yyyy"
                      showMonthDropdown={false}
                      onChange={handleDateChange}
                      value={datechange}
                    />
                  </div>
                  {/* commented out on customize date change request */}
                  {/* <div className={colmn}>
                    <input
                      placeholder="Time"
                      type="text"
                      onChange={handleTimeChange}
                      className="form-control"
                      value={timechange}
                      onBlur={handleTimeBlur}
                      maxLength="6"
                      pattern="/((1[0-2]|0?[1-9]):([0-5][0-9])/"
                      onKeyDown={(event)=>{
                        if(event.key === 'Enter'){applyfilter();}
                  }} 
                    />
                  </div>
                  <div className={colmn}>
                    <input
                      placeholder="Duration"
                      type="text"
                      onChange={handleDurationChange}
                      className="form-control"
                      value={durationchange}
                      onBlur={handleDurationBlur}
                      maxLength="6"
                      pattern="/[0-9]*(\.[0-9]{0,2})?$/"
                      onKeyDown={(event)=>{
                        if(event.key === 'Enter'){applyfilter();}
                  }} 
                    />
                  </div> */}
                  {Utility.IsHearingOfficer() ? null : (
                    <div className={colmn}>
                      <input
                        type="text"
                        placeholder="Hearing Officer"
                        onChange={handleneutralNameChange}
                        disabled={isLoading}
                        className="form-control"
                        value={neutralNamechange}
                        onBlur={handleneutralNameBlur}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            applyfilter();
                          }
                        }}
                      />
                    </div>
                  )}
                  <div className={colmn}>
                    <select
                      value={statusChange}
                      onChange={(e) => handleStatusChange(e)}
                      className="SelectField"
                      name="sortBy"
                      id="sortBy"
                    >
                      <option value="">Status</option>
                      {caseStatusList !== null &&
                      caseStatusList !== undefined &&
                      Object.keys(caseStatusList).length ? (
                        Object.keys(caseStatusList).map((value) => (
                          <option
                            key={value}
                            value={value}
                          >
                            {value}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                      {/* <option value="">Status</option>
                      <option value="ADJOURNED">ADJOURNED</option>
                      <option value="CANCELLED">CANCELLED</option>
                      <option value="CLOSED">CLOSED</option>
                      <option value="COMPLETED">COMPLETED</option>
                      <option value="CONTINUED">CONTINUED</option>
                      <option value="DECISION">DECISION</option>
                      <option value="HEARD">HEARD</option>
                      <option value="HOLD">HOLD</option>
                      <option value="PREHOLD">PREHOLD</option>
                      <option value="SCHEDULED">SCHEDULED</option>
                      <option value="SETTLED">SETTLED</option> */}
                    </select>
                  </div>

                  <div className="col-md-12 col-12 filtersbtn">
                    <button
                      onClick={applyfilter}
                      className="btn btn-primary"
                      disabled={disableFilter}
                    >
                      Apply Filter
                    </button>
                    <button
                      onClick={clearfilter}
                      className="btn btn-primary"
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
                <div className="eventTopPagination">
                  <div className="caseTablePagination">
                    <div className="controlDiv">
                      <div className="eventTypes">
                        {!Utility.IsHearingOfficer() && (
                          <div className="eventList">
                            <span>
                              <i className="ccircle">C</i>
                            </span>
                            <p>Commercial</p>
                          </div>
                        )}
                        <div className="eventList">
                          <CircleIcon
                            style={{
                              color: "#F27C47",
                              fontSize: "0.6rem",
                              marginRight: "5px",
                            }}
                            size="small"
                          />
                          <p>Arbitration Event</p>
                        </div>
                        <div className="eventList">
                          <CircleIcon
                            style={{
                              color: "black",
                              fontSize: "0.6rem",
                              marginRight: "5px",
                            }}
                            size="small"
                          />
                          <p>Mediation Event</p>
                        </div>
                        <div className="eventList">{renderMassFileIcon()}</div>
                      </div>
                    </div>
                  </div>

                  <div className="page">
                    <div className="perPagecounter">
                      <p style={{ padding: "6px", backgroundColor: "#e8e8e8" }}>
                        Per Page
                      </p>
                      <select
                        defaultValue={recordPerPage}
                        onChange={(e) => handleSelectChange(e)}
                        className="SelectField pageSize"
                        name="sortBy"
                        id="sortBy"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                    <div className="showingResContainer">
                      <p>
                        Showing{" "}
                        {totalRecords == 0
                          ? totalRecords
                          : pageNumber > 1
                          ? (pageNumber - 1) * recordPerPage + 1
                          : pageNumber}{" "}
                        -{" "}
                        {pageNumber * recordPerPage <= totalRecords
                          ? pageNumber * recordPerPage
                          : totalRecords}{" "}
                        of {totalRecords} records
                      </p>
                    </div>
                    <div className="paginDiv">
                      {totalRecords > 0 ? (
                        <Paginator
                          onMount={onChildMount}
                          rowCount={recordPerPage}
                          className="pagination"
                          totalRecoards={totalRecords}
                          recordsPerPage={recordPerPage}
                          onPageChange={onChangePage}
                          maxPageLimit={5}
                          initialPage={pageNumber}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="displaytimezone">
                  <div className="eventList">
                    <p>All times in {Utility.userSpecificTimeZone()}</p>
                  </div>
                </div>

                <MatDatagrid
                  draggable={false}
                  rows={displayProducts ? displayProducts : []}
                  columns={columns}
                  grouping={false}
                  pageSize={recordPerPage}
                  title={" "}
                  paging={false}
                  showFirstLastPageButtons={false}
                  paginationType={"stepped"}
                  filtering={false}
                  pageSizeOptions={[5, 10, 15, 20]}
                  onFilterChange={onFilterChange}
                  customPagination={{
                    isDisplay: true,
                    totalCount: totalRecords,
                  }}
                  isCustomHeader={true}
                  selection={false}
                  actionsColumnIndex={-1}
                  onOrderChange={onOrderChange}
                  className="caseTable"
                  orderDirection={orderDirection}
                  orderBy={orderByColumn}
                />
              </div>
            )}
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <div className="LoadingData">
            <iframe
              src={Loading}
              width="100%"
              allowtransparency="true"
              frameBorder="0"
              marginWidth="0"
              marginHeight="0"
              scrolling="no"
            ></iframe>

            <h4>Loading...</h4>
          </div>
        )}
      </div>
    </LoadingOverlay>
  );
};
export default Cases;
