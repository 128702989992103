import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, Button, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./CaseInfo.scss";
import CaseData from "./caseData";
import MatDatagrid from "f1-mat-data-grid-react";
import { Paginator } from "../../shared/paginator";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSelectedCaseForDecisionApply } from "../../actions/caseAction";
import { encryptItem } from "../../shared/Encrypt";
import {
  GET_CASE_DECISOIN_STATUS,
  GET_MASS_FILES_CASES_GRID,
  PMA_DETAILS,
} from "../../api/baseURL";
import LoadingOverlay from "react-loading-overlay";

const CaseInfo = (props) => {
  const dispatch = useDispatch();
  const userToken = localStorage.getItem("token");
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageNumber, setpageNumber] = useState(1);
  const [recordPerPage, setrecordPerPage] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [cases, setCases] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("caseId");
  const [orderByColumn, setOrderByColumn] = useState(0);
  const [orderDirection, setOrderDirection] = useState("asc");
  const subGroupId = props.hearingDetails?.subGroupId;
  const [pmaDetailsForSelectedCases, setPmaDetailsForSelectedCases] = useState(
    []
  );

  useEffect(() => {
    getCases();
  }, [orderBy, orderByColumn, orderDirection, pageNumber, recordPerPage]);

  //call api to fetch cases from group id
  const getCases = () => {
    if (!isLoading) {
      setIsLoading(true);
    }

    const { massFileId, groupId, subGroupId } = props.hearingDetails;

    const url = GET_MASS_FILES_CASES_GRID;

    axios
      .post(
        url,
        {
          sortDirection: orderDirection,
          localSearch: "",
          massFileId: massFileId,
          exactMatch: true,
          filters: [],
          pageNumber: pageNumber,
          pageSize: recordPerPage,
          sort: orderBy,
          search: searchText,
          groupId,
          subGroupId,
        },
        { headers: { Authorization: `Bearer ${userToken}` } }
      )
      .then((res) => {
        const { data } = res.data;
        setTotalRecords(data.length);

        const mappedCases = data.map((x) => ({
          ...x,
          id: x.caseId,
          caseId: x.caseId,
          caseCaption: x.caseCaption,
          caseGroup: x.caseGroup,
          caseType: x.caseTypeName,
          createdOn: moment(x.createdOn).format("MMM-DD-YY"),
          caseStatus: x.caseStatus,
          aeCm: x.aE_CM,
          decision: "Create | View | Edit",
        }));

        axios
          .post(
            GET_CASE_DECISOIN_STATUS,
            mappedCases.map((x) => x.caseId),
            {
              headers: { Authorization: `Bearer ${userToken}` },
            }
          )
          .then((decisionResponse) => {
            const { data } = decisionResponse;
            mappedCases.forEach((ca) => {
              const matchedCase = data.find((d) => d.caseId === ca.caseId);

              if (matchedCase) {
                ca.isDecisionExist = matchedCase.isDecisionExist;
              }
            });

            setCases([...mappedCases]);
            setRows([...mappedCases]);

            encryptItem("massFileCasesForDecisionTemplate", [...mappedCases]);
            loadPmaDetails([...mappedCases]);
          })
          .catch((err) => {
            setCases([...mappedCases]);
            setRows([...mappedCases]);
            setIsLoading(false);
            encryptItem("massFileCasesForDecisionTemplate", [...mappedCases]);
          });
      })
      .catch((err) => {
        setCases([]);
        setRows([]);
        setIsLoading(false);
      });
  };

  const getPmaDetailsForMassFileCases = async (hearingCode, caseCode) => {
    const url = `${PMA_DETAILS}?hearingCode=${hearingCode}&caseCode=${caseCode}`;

    try {
      const { data } = await axios.get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      let tempresponse = data;
      if (
        tempresponse &&
        tempresponse.timeSheets !== undefined &&
        tempresponse.timeSheets !== null &&
        tempresponse.timeSheets.length > 0
      ) {
        tempresponse.timeSheets = tempresponse.timeSheets
          .slice()
          .sort((a, b) => (b.hearingDate < a.hearingDate ? 1 : -1));
      }

      tempresponse.updatedDate = new Date();

      return tempresponse;
    } catch (err) {
      return null;
    }
  };

  const loadPmaDetails = async (casesList) => {
    const promises = casesList.map(async (item) => {
      return await getPmaDetailsForMassFileCases(
        props.hearingDetails.hearingCode,
        item.caseCode
      ); // Use async/await to process each item
    });

    const results = await Promise.all(promises);

    setIsLoading(false);

    setPmaDetailsForSelectedCases(results);
  };

  const onOrderChange = (column, event) => {
    setOrderByColumn(column);
    setOrderDirection(event);

    const field = columns[column].field[0];
    setOrderBy(field);
  };

  const onClickCreate = (rowData) => {
    const { massFileId, groupId, subGroupId } = props.hearingDetails;
    props.changeTab("decision");
    dispatch(
      setSelectedCaseForDecisionApply({
        massFileId,
        groupId,
        subGroupId,
        ...rowData,
      })
    );
  };

  // Table columns
  const columns = [
    {
      field: "caseId",
      title: "Case ID",
      flex: 1,
    },
    { field: "caseCaption", title: "Case Caption", flex: 1 },
    { field: "caseGroup", title: "Case Group", flex: 1 },
    { field: "caseType", title: "Case Type", flex: 1 },
    { field: "createdOn", title: "Created On", sorting: false, flex: 1 },
    { field: "caseStatus", title: "Case Status", flex: 1 },
    { field: "aeCm", title: "AE / CM", flex: 1 },
    {
      field: "decision",
      title: "Decision",
      flex: 2,
      sorting: false,
      filtering: false,
      render: (rowData) => {
        const isDocSent =
          pmaDetailsForSelectedCases.find(
            (x) => x.caseCode.toString() === rowData.caseCode.toString()
          )?.status === "sent";

        return (
          <div
            style={{ display: "flex", alignItems: "center", minWidth: "200px" }}
            className="case-info-action-buttons"
          >
            {rowData.isDecisionExist ? (
              <Tooltip title="Decision template has been applied to this case">
                <span>
                  <Button disabled>Create |</Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                onClick={() => onClickCreate(rowData)}
                disabled={rowData.isDecisionExist}
                disableRipple={true}
                disableTouchRipple={true}
              >
                Create |
              </Button>
            )}
            <Button onClick={() => props.changeTab("decision")}>
              View{" "}
              <span
                className={rowData.isDecisionExist ? "disabled-pipe" : "pipe"}
              >
                |
              </span>
            </Button>
            {isDocSent ? (
              <Tooltip title="Decision cannot be edited as sent for signatures">
                <span>
                  <Button disabled>Edit</Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                onClick={() => props.changeTab("decision")}
                disabled={isDocSent}
              >
                Edit
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    // Add search logic to filter rows
  };
  const onChildMount = (dataFromChild) => {
    console.log("child mount");
  };
  const handleSelectChange = (e) => {
    const size = e.target.value;
    setrecordPerPage(+size);
    setpageNumber(1);
  };

  const onChangePage = (e, page) => {
    setpageNumber(page + 1);
  };

  const onClickSearchIcon = () => {
    getCases();
  };

  console.log(cases, "cases");

  return (
    <LoadingOverlay
      spinner
      active={isLoading}
      text="Please wait working on your request..."
    >
      <div className="row casestructure">
        <CaseData
          caseDetails={props.caseDetails}
          subGroupId={subGroupId}
        />
      </div>
      <div className="case-info-search-box">
        <TextField
          variant="outlined"
          placeholder="Search CaseId, Case Caption, CaseGroup, CaseType, Case Status, AE/CM..."
          fullWidth
          value={searchText}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => onClickSearchIcon()}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <MatDatagrid
          rows={rows || []}
          columns={columns}
          grouping={false}
          pageSize={rows.length}
          title={" "}
          paging={false}
          showFirstLastPageButtons={false}
          paginationType={"stepped"}
          filtering={false}
          pageSizeOptions={[5, 10, 15, 20]}
          onChangePage={onChangePage}
          onOrderChange={onOrderChange}
          orderBy={orderByColumn}
          customPagination={{
            isDisplay: true,
            totalCount: rows.length,
          }}
          isCustomHeader={true}
          selection={false}
          actionsColumnIndex={-1}
          draggable={false}
          sorting={true}
        />
      </div>
      <div className="page">
        <div className="perPagecounter">
          <p style={{ padding: "6px", backgroundColor: "#e8e8e8" }}>Per Page</p>
          <select
            defaultValue={recordPerPage}
            onChange={(e) => handleSelectChange(e)}
            className="SelectField pageSize"
            name="sortBy"
            id="sortBy"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>
        <div className="showingResContainer">
          <p>
            Showing{" "}
            {totalRecords === 0
              ? totalRecords
              : pageNumber > 1
              ? (pageNumber - 1) * recordPerPage + 1
              : pageNumber}{" "}
            -{" "}
            {pageNumber * recordPerPage <= totalRecords
              ? pageNumber * recordPerPage
              : totalRecords}{" "}
            of {totalRecords} records
          </p>
        </div>
        <div className="paginDiv">
          {totalRecords > 0 ? (
            <Paginator
              onMount={onChildMount}
              rowCount={recordPerPage}
              className="pagination"
              totalRecoards={totalRecords}
              recordsPerPage={recordPerPage}
              onPageChange={onChangePage}
              maxPageLimit={5}
              initialPage={pageNumber}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default CaseInfo;
