import * as types from "../constants/userConstant";
const initialState = {
  caseresponse: {},
  selectedCaseForDecisionApply: null,
  selectedMassFileHearing: null,
};

function casereducer(state = initialState, action) {
  switch (action.type) {
    case types.CASE_SUCCESS:
      return {
        ...state,
        caseresponse: action.reesponse,
      };
    case types.SET_SELECTED_CASE_FOR_DECISION_APPLY:
      return {
        ...state,
        selectedCaseForDecisionApply: action.selectedCaseForDecisionApply,
      };
    case types.SET_SELECTED_MASS_FILE_HEARING:
      return {
        ...state,
        selectedMassFileHearing: action.selectedMassFileHearing,
      };
    default:
      return state;
  }
}

export default casereducer;
