//Features
export const hasReadPermissionFeature = (feature) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      return permission.read;
    }
  }
  return false;
};

export const hasWritePermissionFeature = (feature) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      return permission.write;
    }
  }
  return false;
};

export const hasPermissionFeature = (feature) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      return permission.read || permission.write;
    }
  }
  return false;
};

//Screens
export const hasReadPermissionScreen = (feature, screen) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        return scr.read;
      }
    }
  }
  return false;
};

export const hasAnyReadPermissionScreen = (feature, screen) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        return scr.fields.some((f) => f.read);
      }
    }
  }
  return false;
};

export const hasWritePermissionScreen = (feature, screen) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        return scr.write;
      }
    }
  }
  return false;
};

export const hasPermissionScreen = (feature, screen) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        return scr.read || scr.write;
      }
    }
  }
  return false;
};

//Fields
export const hasReadPermissionField = (feature, screen, field) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        const fld = scr.fields.find((f) => f.fldName === field);
        if (fld) {
          return fld.read;
        }
      }
    }
  }
  return false;
};

export const hasWritePermissionField = (feature, screen, field) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        const fld = scr.fields.find((f) => f.fldName === field);
        if (fld) {
          return fld.write;
        }
      }
    }
  }
  return false;
};

export const hasPermissionField = (feature, screen, field) => {
  const permissions = getPermissions();
  if (permissions) {
    const permission = permissions.find((p) => p.featName === feature);
    if (permission) {
      const scr = permission.screens.find((s) => s.scrName === screen);
      if (scr) {
        const fld = scr.fields.find((f) => f.fldName === field);
        if (fld) {
          return fld.read || fld.write;
        }
      }
    }
  }
  return false;
};

function getPermissions() {
  return JSON.parse(localStorage.getItem("permissions"));
}
