import React from "react";
import * as Utility from "../../utils/utility";

const CaseData = (props) => {
  const hasMassFile = props.subGroupId && props.subGroupId !== "";

  return (
    <>
      <div className="row mb-1s">
        <div className="col-md-6">
          <div className="row">
            <div className="col-4">
              {" "}
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Case Name
              </label>
            </div>
            <div className="col-8 text-left">
              <span className="form-control-plaintext rightt">
                {props.caseDetails["caseName"]}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-6">
          <div className="row">
            <div className="col-4">
              {" "}
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                {hasMassFile ? "Subgroup ID" : "NAM ID"}
              </label>
            </div>
            <div className="col-8 text-left">
              <span className="form-control-plaintext rightt">
                {hasMassFile
                  ? props.subGroupId
                  : props.caseDetails["caseReference"]}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-3">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Hearing Officer{" "}
              </label>
            </div>
            <div className="col-9 text-left">
              <span
                className="form-control-plaintext rightt"
                style={{ textAlign: "left" }}
              >
                {props.caseDetails["hearingOfficerName"] !== undefined &&
                props.caseDetails["hearingOfficerName"] !== null
                  ? props.caseDetails["hearingOfficerName"]
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-6">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Case Method
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
                style={{ textAlign: "left", textTransform: "capitalize" }}
              >
                {props.caseDetails["caseType"]}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Case Type{" "}
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
                style={{ textAlign: "left", textTransform: "capitalize" }}
              >
                {props.caseDetails["caseType"] !== undefined &&
                props.caseDetails["caseType"] !== null
                  ? props.caseDetails["caseType"]
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1s">
        <div className="col-md-6">
          <div
            className="row"
            style={{ marginBottom: "1rem" }}
          >
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Date of conference
              </label>
            </div>
            <div className="col-8 text-left">
              <span className="form-control-plaintext rightt">
                {Utility.getdate(
                  props.caseDetails["hearingStartTimeUTCFromCMS"]
                )}
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="row">
            <div className="col-4">
              <label
                htmlFor="staticEmail"
                className=" col-form-label float-start text-start leftt"
              >
                Type{" "}
              </label>
            </div>
            <div className="col-8 text-left">
              <span
                className="form-control-plaintext rightt"
                style={{ textAlign: "left" }}
              >
                {props.caseDetails["hearingType"] !== undefined &&
                props.caseDetails["hearingType"] !== null
                  ? props.caseDetails["hearingType"]
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseData;
