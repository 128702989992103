import React, { useEffect, useRef, useState } from "react";
import DynamicForm from "f1-dynamic-form-react";
import MatDatagrid from "f1-mat-data-grid-react";
import "./NeutralVacation.css";
import { NeutralVacationModel } from "./NeutralVacationModels";
import { useSelector } from "react-redux";
import {
  deleteNeutralVacations,
  getNeutralVacationList,
  saveDiaryData,
  saveNeutralVacation,
} from "../../../actions/neutralVacationAction";
import { useDispatch } from "react-redux";
import {
  DELETE_NEUTRAL_VACATION,
  GET_NEUTRAL_VACATION,
  NeutralVacationSource,
  NeutralVacationStatus,
  SAVE_NEUTRAL_VACATION,
} from "../../../constants/userConstant";
import { useHistory } from "react-router-dom";
import { Button, Link, Tooltip } from "@mui/material";
import CustomModalPopup from "./CustomModalPopup";
import LoadingOverlay from "react-loading-overlay";
import { getdate, getDateWithTime } from "../../../utils/utility";
import HelpIcon from "@mui/icons-material/Help";
import { Paginator } from "../../../shared/paginator";
import DatePicker from "react-date-picker";

const NeutralVacation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dyFormRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [dataRows, setDataRows] = useState([]);
  const [disabledCheckboxList, setdisabledCheckboxList] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isMultipleVacationsToDelete, setIsMultipleVacationsToDelete] =
    useState(true);
  const [singleItemToDelete, setSingleItemToDelete] = useState([]);
  let [orderByColumn, setOrderByColumn] = useState(0);
  let [orderDirection, setOrderDirection] = useState("asc");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "";

  const [pageNumber, setpageNumber] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(20);
  const [totalRecords, settotalRecords] = useState(0);

  const [defaultModelValues, setDefaultModelValues] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    note: "",
  });

  const gridFilterValuesInitialState = {
    createDate: null,
    startDate: null,
    endDate: null,
    note: "",
    status: "",
  };

  const [gridFilterValues, setGridFilterValues] = useState(
    gridFilterValuesInitialState
  );

  const [modalMsg, setModalMsg] = useState({
    header: "",
    alertText: "",
    show: false,
    eventType: "",
    showOkButtonOnly: false,
    showCloseButton: true,
  });

  const neutralVacationGridModelInitialState = {
    neutralId: localStorage.getItem("userid"),
    localSearch: "",
    exactMatch: true,
    pageNumber: 1,
    pageSize: recordPerPage,
    sort: "",
    sortDirection: "",
    filters: [],
    source: NeutralVacationSource.NP,
    timeZone: userTimeZone,
  };

  const diaryRequestBody = {
    ScopeName: "neutralvacation",
    Notes: "",
    Source: "",
    Priority: "Medium",
    CreatedById: localStorage.getItem("userid"),
    CreatedByName: "System",
    NeutralVacationId: 0,
  };

  const [neutralVacationGridModel, setNeutralVacationGridModel] = useState(
    neutralVacationGridModelInitialState
  );

  const neutralVacationsState = useSelector(
    (state) => state.neutralvacationreducer.neutralVacationsState
  );

  const saveNeutralVacationState = useSelector(
    (state) => state.neutralvacationreducer.saveNeutralVacationState
  );

  const deleteNeutralVacationState = useSelector(
    (state) => state.neutralvacationreducer.deleteNeutralVacationState
  );

  let neutralVacationModelState = NeutralVacationModel();

  const NeutralVacationRowDataStatus = {
    [NeutralVacationStatus.APPROVED]: "Approved",
    [NeutralVacationStatus.PENDINGFORAPPROVAL]: "Pending for Approval",
    [NeutralVacationStatus.REJECTED]: "Rejected",
  };

  const NeutralVacationGridColumns = [
    {
      title: "Requested On",
      field: "createDate",
      sorting: true,
      filtering: true,
      render: (rowData) => <>{getDateWithTime(rowData?.createDate) || ""}</>,
    },
    {
      title: "Start Date",
      field: "startDate",
      sorting: true,
      filtering: true,
      render: (rowData) => <>{getdate(rowData?.startDate) || ""}</>,
    },
    {
      title: "End Date",
      field: "endDate",
      sorting: true,
      filtering: true,
      render: (rowData) => <>{getdate(rowData?.endDate) || ""}</>,
    },
    {
      title: "Notes",
      field: "note",
      sorting: true,
      filtering: true,
    },
    {
      title: "Status",
      field: "status",
      sorting: true,
      filtering: true,
      render: (rowData) => (
        <>{NeutralVacationRowDataStatus[rowData?.status] || ""}</>
      ),
    },
    {
      title: "Action",
      field: "Action",
      sorting: false,
      filtering: false,
      render: (rowData) => (
        <>
          {rowData?.status === NeutralVacationStatus.APPROVED ||
          rowData?.status === NeutralVacationStatus.REJECTED ? (
            <Tooltip
              title="The vacation request cannot be deleted as it has been either approved or rejected by the NAM employee"
              arrow
            >
              <div className="help-icon-wrapper">
                <HelpIcon className="help-icon" />
              </div>
            </Tooltip>
          ) : (
            <Link
              className="neutral-vacation-delete-button"
              onClick={() => {
                setIsMultipleVacationsToDelete(false);
                setSingleItemToDelete([rowData?.id]);
                deleteMultipleVacations();
              }}
            >
              Delete
            </Link>
          )}
        </>
      ),
    },
  ];

  const onSelectionChange = (data) => {
    if (data.length > 0) {
      let dataArray = [];
      data.forEach((element) => {
        if (
          element?.status !== NeutralVacationStatus.APPROVED ||
          element?.status !== NeutralVacationStatus.REJECTED
        ) {
          dataArray.push(element.id);
        }
      });
      setSelectedItems(dataArray);
    } else {
      setSelectedItems([]);
    }
  };

  const onFilterInputChange = (event) => {};

  const onOrderChange = (column, value) => {
    let order = orderDirection === "asc" ? "desc" : "asc";
    setOrderDirection(order);
    setOrderByColumn(column);
    let columnName = NeutralVacationGridColumns[column].field;

    let neutralVacationGridModelCopy = { ...neutralVacationGridModel };
    neutralVacationGridModelCopy.sort = columnName;
    neutralVacationGridModelCopy.sortDirection = order;
    setNeutralVacationGridModel(neutralVacationGridModelCopy);
  };

  const handleNeutralVacationButtonClick = (id, key, model) => {
    let modalMsgCopy = { ...modalMsg };
    modalMsgCopy.showOkButtonOnly = false;
    modalMsgCopy.showCloseButton = true;

    if (id === "goBackButton") {
      if (isFormUpdated) {
        modalMsgCopy.show = true;
        modalMsgCopy.header = "Unsaved Changes";
        modalMsgCopy.alertText =
          "Do you want to cancel the neutral vacation process? The changes will not be saved.";
        modalMsgCopy.eventType = "GoBack";
        modalMsgCopy.onHide = setModalMsg;
      } else {
        history.push("/CaseCalendar");
      }
    }

    if (id === "addVacationButton") {
      if (Object.keys(model.errors).length > 0) {
        return false;
      }

      dyFormRef.current.validateForm();
      if (!model.isValidForm && Object.keys(model.errors).length > 0) {
        return false;
      }

      //add vacation
      const requestBody = {
        startDate: model.startDate,
        endDate: model.endDate,
        note: model.note,
        neutralId: localStorage.getItem("userid"),
        createdBy: localStorage.getItem("userid"),
        status: NeutralVacationStatus.PENDINGFORAPPROVAL,
        source: NeutralVacationSource.NP,
      };

      setIsLoading(true);
      dispatch(saveNeutralVacation(requestBody));
    }

    setModalMsg(modalMsgCopy);
  };

  const getNeutralVacations = () => {
    setIsLoading(true);
    dispatch(getNeutralVacationList(neutralVacationGridModel));
  };

  const deleteVacations = () => {
    setIsLoading(true);
    let itemsToDelete = isMultipleVacationsToDelete
      ? selectedItems
      : singleItemToDelete;
    dispatch(deleteNeutralVacations(itemsToDelete));
  };

  const handleFormChange = (event, key, model) => {
    setIsFormUpdated(true);
    if (key === "startDate") {
      applyDateValidator(event.target.value, model.endDate, key);
    } else if (key === "endDate") {
      applyDateValidator(model.startDate, event.target.value, key);
    }
    setDefaultModelValues({
      ...defaultModelValues,
      [key]: event.target.value,
    });
  };

  const customValidators = [
    {
      key: "startDate",
      validator: () => true,
      message: "Start Date must be earlier than the End Date",
    },
    {
      key: "endDate",
      validator: () => true,
      message: "End Date must be later than the Start Date",
    },
  ];

  const applyDateValidator = (startDate, endDate, key) => {
    if (new Date(startDate) <= new Date(endDate)) {
      customValidators[0].validator = () => true;
      customValidators[1].validator = () => true;
      dyFormRef.current.validateForm();
    } else if (startDate !== "" && endDate !== "") {
      customValidators.find((x) => x.key === key).validator = () => false;
    }
  };

  const deleteMultipleVacations = () => {
    let modalMsgCopy = { ...modalMsg };
    modalMsgCopy.showOkButtonOnly = false;
    modalMsgCopy.showCloseButton = true;
    modalMsgCopy.show = true;
    modalMsgCopy.header = "Delete Confirmation";
    modalMsgCopy.alertText = isMultipleVacationsToDelete
      ? "Do you want to delete the selected record? The changes will not be undone."
      : "Do you want to delete this record? The changes will not be undone.";
    modalMsgCopy.eventType = "DeleteVacation";
    modalMsgCopy.onHide = setModalMsg;
    setModalMsg(modalMsgCopy);
  };

  const addDiaryData = (neutralVacationId, startDate, endDate) => {
    var diaryEntries = [];
    const hearingOfficerName = localStorage.getItem("hearingOfficerName") || "";
    diaryRequestBody.Source = `Neutral Vacation Request - ${hearingOfficerName}`;
    diaryRequestBody.Notes = `Neutral Vacation Request Neutral: ${hearingOfficerName} Start Date: ${startDate} End Date: ${endDate}`;
    diaryRequestBody.NeutralVacationId = neutralVacationId;
    diaryEntries.push(diaryRequestBody);

    dispatch(saveDiaryData(diaryEntries));
  };

  //modal button click
  const handleModalButtonClick = (event, eventType) => {
    const { name } = event.target;
    let modalMsgCopy = { ...modalMsg };
    modalMsgCopy.show = false;
    setModalMsg(modalMsgCopy);

    if (eventType === "GoBack") {
      if (name === "yes") {
        history.push("/CaseCalendar");
      }
    }

    if (eventType === "Ok") {
      if (name === "ok") {
        getNeutralVacations();
      }
    }

    if (eventType === "DeleteVacation") {
      if (name === "yes") {
        deleteVacations();
      }
      if (name === "ok") {
        getNeutralVacations();
      }
    }
  };

  //pagination
  const handleSelectChange = (e) => {
    const size = e.target.value;
    setRecordPerPage(+size);
    setpageNumber(1);
    let neutralVacationGridModelCopy = { ...neutralVacationGridModel };
    neutralVacationGridModelCopy.pageSize = +size;
    neutralVacationGridModelCopy.pageNumber = 1;
    setNeutralVacationGridModel(neutralVacationGridModelCopy);
  };

  const onChangePage = (e, page) => {
    setpageNumber(page + 1);
    let neutralVacationGridModelCopy = { ...neutralVacationGridModel };
    neutralVacationGridModelCopy.pageNumber = page + 1;
    setNeutralVacationGridModel(neutralVacationGridModelCopy);
  };

  // grid filter
  const onGridFilterChange = (event, key) => {
    const value =
      key === "createDate" || key === "startDate" || key === "endDate"
        ? event
        : event.target.value;

    setGridFilterValues({
      ...gridFilterValues,
      [key]: value,
    });
  };

  const getFieldType = (field) => {
    const dateFields = ["createDate", "startDate", "endDate"];
    const stringFields = ["note", "status"];

    if (dateFields.includes(field)) return "date";
    if (stringFields.includes(field)) return "string";
    return "";
  };

  const applyFilterValues = () => {
    const filters = Object.entries(gridFilterValues || [])
      .filter(([key, value]) => value !== null && value !== "")
      .map(([key, value]) => {
        const filterType = getFieldType(key);
        return {
          filterName: key,
          filterValue:
            filterType === "date" ? value.toLocaleDateString("en-CA") : value,
          filterType: filterType,
          filterEq: "",
        };
      });

    if (filters && filters.length > 0) {
      let neutralVacationGridModelCopy = { ...neutralVacationGridModel };
      neutralVacationGridModelCopy.filters = filters;
      setNeutralVacationGridModel(neutralVacationGridModelCopy);
    }
  };

  const disableApplyFilterButton = Object.values(gridFilterValues).every(
    (value) => value === null || value === ""
  );

  useEffect(() => {
    getNeutralVacations();
  }, [neutralVacationGridModel]);

  useEffect(() => {
    if (neutralVacationsState) {
      let modalMsgCopy = { ...modalMsg };
      modalMsgCopy.showOkButtonOnly = false;
      modalMsgCopy.showCloseButton = true;
      setIsLoading(false);

      if (neutralVacationsState?.status === 200) {
        let response = neutralVacationsState?.data;
        let suspendedObjects = response?.data?.filter(
          (obj) =>
            obj?.status === NeutralVacationStatus.APPROVED ||
            obj?.status === NeutralVacationStatus.REJECTED
        );
        const ids = suspendedObjects?.map((obj) => obj.id);
        setdisabledCheckboxList({ ids: ids, key: "id" });
        setDataRows(response?.data);
        settotalRecords(response?.totalCount || 0);
      } else {
        modalMsgCopy.show = true;
        modalMsgCopy.showOkButtonOnly = true;
        modalMsgCopy.header = "";
        modalMsgCopy.alertText = "Failed to retrieve data. Please try again.";
        modalMsgCopy.eventType = "Failed";
        modalMsgCopy.onHide = setModalMsg;
      }

      setModalMsg(modalMsgCopy);
      dispatch({
        type: GET_NEUTRAL_VACATION,
        response: undefined,
      });
    }
  }, [neutralVacationsState]);

  useEffect(() => {
    if (saveNeutralVacationState) {
      let modalMsgCopy = { ...modalMsg };
      modalMsgCopy.showOkButtonOnly = false;
      modalMsgCopy.showCloseButton = true;
      setIsLoading(false);

      if (saveNeutralVacationState?.status === 200) {
        if (
          saveNeutralVacationState?.data?.isSuccess === false &&
          saveNeutralVacationState?.data?.statusCode === "Invalid"
        ) {
          //Duplicate information
          if (
            saveNeutralVacationState?.data?.status === "DuplicateInformation"
          ) {
            modalMsgCopy.show = true;
            modalMsgCopy.showOkButtonOnly = true;
            modalMsgCopy.header = "Duplicate Information!";
            modalMsgCopy.alertText = (
              <>
                The vacation of the neutral{" "}
                {localStorage.getItem("hearingOfficerName")}
                &nbsp;for&nbsp;
                {getdate(defaultModelValues.startDate)}
                &nbsp;to&nbsp;
                {getdate(defaultModelValues.endDate)}
                &nbsp;already exists. Please select other dates to mark the
                vacation of the neutral.
              </>
            );
            modalMsgCopy.eventType = "Duplicate";
            modalMsgCopy.onHide = setModalMsg;
          }

          //Hearing Conflict!
          if (saveNeutralVacationState?.data?.status === "HearingConflict") {
            modalMsgCopy.show = true;
            modalMsgCopy.showOkButtonOnly = true;
            modalMsgCopy.header = "Hearing Conflict!";
            modalMsgCopy.alertText = (
              <>
                The hearing is scheduled for the neutral &nbsp;
                {localStorage.getItem("hearingOfficerName")} on{" "}
                {getdate(saveNeutralVacationState?.data?.dateOfHearing)}. &nbsp;
                Please select another day to mark the vacation of the neutral.
              </>
            );
            modalMsgCopy.eventType = "HearingConflict";
            modalMsgCopy.onHide = setModalMsg;
          }
        } else {
          modalMsgCopy.show = true;
          modalMsgCopy.showOkButtonOnly = true;
          modalMsgCopy.header = "";
          modalMsgCopy.alertText = "Vacation request submitted successfully.";
          modalMsgCopy.eventType = "Ok";
          modalMsgCopy.onHide = setModalMsg;
          addDiaryData(
            saveNeutralVacationState?.data?.id,
            defaultModelValues.startDate,
            defaultModelValues.endDate
          );
          setDefaultModelValues({
            ...defaultModelValues,
            startDate: new Date().toISOString().split("T")[0],
            endDate: new Date().toISOString().split("T")[0],
            note: "",
          });
          setIsFormUpdated(false);
        }
      } else {
        modalMsgCopy.show = true;
        modalMsgCopy.showOkButtonOnly = true;
        modalMsgCopy.header = "";
        modalMsgCopy.alertText =
          "Failed to submit vacation request. Please try again.";
        modalMsgCopy.eventType = "Failed";
        modalMsgCopy.onHide = setModalMsg;
      }

      setModalMsg(modalMsgCopy);
      dispatch({
        type: SAVE_NEUTRAL_VACATION,
        response: undefined,
      });
    }
  }, [saveNeutralVacationState]);

  useEffect(() => {
    if (deleteNeutralVacationState) {
      let modalMsgCopy = { ...modalMsg };
      modalMsgCopy.showOkButtonOnly = false;
      modalMsgCopy.showCloseButton = true;
      setIsLoading(false);

      if (deleteNeutralVacationState?.status === 200) {
        modalMsgCopy.show = true;
        modalMsgCopy.showOkButtonOnly = true;
        modalMsgCopy.header = "";
        modalMsgCopy.alertText = "Vacation deleted successfully.";
        modalMsgCopy.eventType = "DeleteVacation";
        modalMsgCopy.onHide = setModalMsg;
      } else {
        modalMsgCopy.show = true;
        modalMsgCopy.showOkButtonOnly = true;
        modalMsgCopy.header = "";
        modalMsgCopy.alertText = "Failed to delete vacation. Please try again.";
        modalMsgCopy.eventType = "DeleteVacation";
        modalMsgCopy.onHide = setModalMsg;
      }

      setModalMsg(modalMsgCopy);
      setSelectedItems([]);
      dispatch({
        type: DELETE_NEUTRAL_VACATION,
        response: undefined,
      });
    }
  }, [deleteNeutralVacationState]);

  return (
    <LoadingOverlay active={isLoading} spinner text="Loading your content...">
      <CustomModalPopup
        show={modalMsg.show}
        header={modalMsg.header}
        alertText={modalMsg.alertText}
        onButtonClick={handleModalButtonClick}
        eventType={modalMsg.eventType}
        onHidePopup={modalMsg.onHide}
        showOkButtonOnly={modalMsg.showOkButtonOnly}
        showCloseButton={modalMsg.showCloseButton}
      />

      <div className="neutral-vacation">
        <div className="neutral-vacation-form">
          <div className="left-column">Neutral Vacation</div>
          <div className="right-column">
            <DynamicForm
              onRef={(ref) => (dyFormRef.current = ref)}
              design="advanceBootstrap"
              title=" "
              model={neutralVacationModelState}
              defaultValues={defaultModelValues}
              defaultAccordianOpenStates={{
                neutralVacationFieldset: true,
              }}
              materialOutline={true}
              onChange={(event, key, model) =>
                handleFormChange(event, key, model)
              }
              buttonNormalClick={(id, key, model) =>
                handleNeutralVacationButtonClick(id, key, model)
              }
              showAstersikOnRequiredFields={true}
              customValidators={customValidators}
            ></DynamicForm>
          </div>
        </div>

        <div className="neutral-vacation-grid-filter-wrapper">
          <div className="neutral-vacation-grid-filter-inputs-wrapper">
            <div className="neutral-vacation-grid-filter-inputs">
              <label>Requested On</label>
              <DatePicker
                locale="en-US"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                format="dd/MMM/yyyy"
                showMonthDropdown={false}
                onChange={(event) => {
                  onGridFilterChange(event, "createDate");
                }}
                value={gridFilterValues.createDate}
              />
            </div>
            <div className="neutral-vacation-grid-filter-inputs">
              <label>Start Date</label>
              <DatePicker
                locale="en-US"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                format="dd/MMM/yyyy"
                showMonthDropdown={false}
                onChange={(event) => {
                  onGridFilterChange(event, "startDate");
                }}
                value={gridFilterValues.startDate}
              />
            </div>
            <div className="neutral-vacation-grid-filter-inputs">
              <label>End Date</label>
              <DatePicker
                locale="en-US"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                format="dd/MMM/yyyy"
                showMonthDropdown={false}
                onChange={(event) => {
                  onGridFilterChange(event, "endDate");
                }}
                value={gridFilterValues.endDate}
              />
            </div>

            <div className="neutral-vacation-grid-filter-inputs neutral-vacation-grid-filter-notes">
              <input
                type="text"
                placeholder="Notes"
                onChange={(event) => {
                  onGridFilterChange(event, "note");
                }}
                id="neutralVacationGridNotesFilter"
                value={gridFilterValues?.note}
              />
            </div>
            <div className="neutral-vacation-grid-filter-inputs neutral-vacation-grid-filter-select">
              <select
                value={gridFilterValues.status}
                onChange={(event) => {
                  onGridFilterChange(event, "status");
                }}
                id="neutralVacationGridStatusFilter"
              >
                <option value="">Select Status</option>
                <option value={NeutralVacationStatus.APPROVED}>
                  {NeutralVacationRowDataStatus[NeutralVacationStatus.APPROVED]}
                </option>
                <option value={NeutralVacationStatus.PENDINGFORAPPROVAL}>
                  {
                    NeutralVacationRowDataStatus[
                      NeutralVacationStatus.PENDINGFORAPPROVAL
                    ]
                  }
                </option>
                <option value={NeutralVacationStatus.REJECTED}>
                  {NeutralVacationRowDataStatus[NeutralVacationStatus.REJECTED]}
                </option>
              </select>
            </div>
          </div>

          <div className="neutral-vacation-grid-filter-buttons-wrapper">
            <div className="neutral-vacation-grid-filter-buttons">
              <button
                onClick={() => {
                  applyFilterValues();
                }}
                className="btn btn-primary"
                disabled={disableApplyFilterButton}
              >
                Apply Filter
              </button>
              <button
                onClick={() => {
                  setpageNumber(1);
                  setGridFilterValues(gridFilterValuesInitialState);
                  setNeutralVacationGridModel(
                    neutralVacationGridModelInitialState
                  );
                }}
                className="btn btn-primary"
              >
                Clear Filter
              </button>
            </div>
          </div>
        </div>

        <div
          className={`neutral-vacation-grid ${
            dataRows && dataRows.length > 0
              ? ""
              : "neutral-vacation-grid-nodata"
          }`}
        >
          <div className="neutral-vacation-grid-header-wrapper">
            <div className="delete-multiple-vacation-button-wrapper">
              <Button
                id="deleteMultipleVacationButton"
                className="delete-multiple-vacation-button"
                onClick={() => {
                  setIsMultipleVacationsToDelete(true);
                  deleteMultipleVacations();
                }}
                disabled={!selectedItems?.length}
              >
                Delete
              </Button>
            </div>

            <div className="page">
              <div className="perPagecounter">
                <p style={{ padding: "6px", backgroundColor: "#e8e8e8" }}>
                  Per Page
                </p>
                <select
                  defaultValue={recordPerPage}
                  onChange={(e) => handleSelectChange(e)}
                  className="SelectField pageSize"
                  name="sortBy"
                  id="sortBy"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                </select>
              </div>
              <div className="showingResContainer">
                <p>
                  Showing{" "}
                  {totalRecords == 0
                    ? totalRecords
                    : pageNumber > 1
                    ? (pageNumber - 1) * recordPerPage + 1
                    : pageNumber}{" "}
                  -{" "}
                  {pageNumber * recordPerPage <= totalRecords
                    ? pageNumber * recordPerPage
                    : totalRecords}{" "}
                  of {totalRecords} records
                </p>
              </div>
              <div className="paginDiv">
                {totalRecords > 0 ? (
                  <Paginator
                    rowCount={recordPerPage}
                    className="pagination"
                    totalRecoards={totalRecords}
                    recordsPerPage={recordPerPage}
                    onPageChange={onChangePage}
                    maxPageLimit={5}
                    initialPage={pageNumber}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <MatDatagrid
            draggable={false}
            id="neutralvacationmatdatagrid"
            title=" "
            rows={dataRows}
            columns={NeutralVacationGridColumns}
            search={true}
            grouping={false}
            onSelectionChange={onSelectionChange}
            treeView={false}
            selection={true}
            selectionId={disabledCheckboxList}
            filtering={false}
            paging={false}
            pageSize={recordPerPage}
            pageSizeOptions={[5, 10, 15, 20]}
            showFirstLastPageButtons={false}
            paginationType={"stepped"}
            customPagination={{
              isDisplay: true,
              totalCount: totalRecords,
            }}
            exportButton={false}
            actionsColumnIndex={-1}
            sorting={true}
            onFilterChange={onFilterInputChange}
            onOrderChange={onOrderChange}
            orderDirection={orderDirection}
            orderBy={orderByColumn}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default NeutralVacation;
