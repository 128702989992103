import React, { useState, useEffect } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Divider from "@mui/material/Divider";
import "./cases.scss";
import {
  HEARING_DETAILS,
  INSERT_FILE,
  PMA_DETAILS,
  HTML_TO_PDF,
  GET_FILEWITHSAS,
} from "../../api/baseURL";
import axios from "axios";
import * as Utility from "../../utils/utility";
import "./SchedulingInfo.scss";
import SchedulingTemplate from "./SchedulingTemplate.js";
import SchedulingTemplateAfter48hrs from "./SchedulingTemplateAfter48hrs.js";
// Plugins
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { decryptItem } from "../../shared/Encrypt";

export default function SchedulingInfo(props) {
  const defaultInstance = defaultLayoutPlugin();
  const hearingCode = parseInt(
    props.hearingCode ? props.hearingCode : props.match.params?.hearingCode
  );
  useEffect(() => {
    getHearingDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [caseDetails, setcaseDetails] = useState({});
  const [pmaDetails, setPMADetails] = useState({});
  const [showPDF, setshowPDF] = useState(false);
  const [showDiv, setshowDiv] = useState(false);
  const userToken = localStorage.getItem("token");
  const [pdfURL, setpdfURL] = useState("");
  const [zoomdetails, setzoomDetails] = useState({});
  const [attendies, setAttendies] = useState("");
  const callBackForZoomDetails = (Details) => {
    // props.callBackzoomdetails(Details);
    setzoomDetails(Details);
  };

  const callBackForAttenddiesSchedular = (attenddies) => {
    setAttendies(attenddies);
    props.callBackForAttenddies(attenddies);
  };

  const getHearingDetails = () => {
    const neutralEmail = decryptItem("neutralsEmail");
    const url = `${HEARING_DETAILS}?hearingCode=${hearingCode}&neutralEmail=${neutralEmail}&userId=${
      Utility.IsHearingOfficer() ? decryptItem("jCode") : decryptItem("userid")
    }&roleId=${decryptItem("roleId")}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((res) => {
        let utcTimeSheets = [];
        if (
          res?.data &&
          Object.keys(res?.data).length > 0 &&
          res.data.timeSheets
        ) {
          res.data.timeSheets.forEach((element) => {
            element.hearingStartTime =
              element.hearingStartTimeUTC &&
              (element.hearingStartTimeUTC.indexOf("GMT")
                ? Utility.convertUTCDateToUserTimeZone(
                    element.hearingStartTimeUTC
                  )
                : Utility.convertUTCDateToUserTimeZone(
                    new Date(element.hearingStartTimeUTC).toUTCString()
                  ));

            element.hearingEndTime =
              element.hearingEndTimeUTC &&
              (element.hearingEndTimeUTC.indexOf("GMT")
                ? Utility.convertUTCDateToUserTimeZone(
                    element.hearingEndTimeUTC
                  )
                : Utility.convertUTCDateToUserTimeZone(
                    new Date(element.hearingEndTimeUTC).toUTCString()
                  ));
            utcTimeSheets.push(element);
          });
        }
        if (res.data.timeSheets && res.data.timeSheets.length > 0) {
          res.data.timeSheets = utcTimeSheets;
          res.data.hearingStartTime = utcTimeSheets[0].hearingStartTime;
          res.data.hearingEndTime = utcTimeSheets[0].hearingEndTime;
          res.data.hearingDate = utcTimeSheets[0].hearingStartTime;
        } else {
          res.data.hearingStartTime = Utility.convertUTCDateToUserTimeZone(
            new Date(res.data.hearingStartTimeUTCFromCMS).toUTCString()
          );
          res.data.hearingEndTime = Utility.convertUTCDateToUserTimeZone(
            new Date(res.data.hearingEndTimeUTCFromCMS).toUTCString()
          );
        }
        setcaseDetails(res.data);
        getPMADetails();
        pushdocument(res.data);
      });
  };
  const getPMADetails = () => {
    const url = `${PMA_DETAILS}?hearingCode=${hearingCode}&caseCode=${props.caseDetails["caseCode"]}`;
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        if (res.status === 200) {
          res.data &&
            res.data.parties &&
            res.data.parties.map((s) => {
              if (s.type === "PLAINTIFF") {
                res.data.partiesdtl = s;
              }
            });
          setPMADetails(res.data);
        }
      });
  };

  useEffect(() => {
    if (Object.keys(caseDetails).length > 0) {
      //   props.isRender()
    }
  }, [caseDetails]);
  const dataURLtoFile = (dataurl, filename) => {
    try {
      const arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    } catch (ex) {}
  };
  const pushdocument = (hearingData, PMAData) => {
    if (document.getElementById("schedulingTemplate") !== null) {
      const pdfname =
        new Date(hearingData.hearingDate).toJSON().slice(0, 10) + "_sch";
      var htmltopdf = document.getElementById("schedulingTemplate").innerHTML;
      //Always Showing PDF...
      // if (Utility.IsHearingOfficer()) {
      //     if (hearingData.hearingDate !== null) {
      //         const hearingDate = new Date(hearingData.hearingDate);
      //         const todayDt = new Date();
      //         const twodaysplusDate = new Date(todayDt.setDate(todayDt.getDate() + 2));
      //         if (hearingDate > todayDt) {
      //             if (hearingDate > twodaysplusDate) {
      //                 setshowDiv(true);
      //                 return;
      //             }
      //         }
      //     }
      // }

      setshowPDF(true);
      const htmltopdfurl = `${HTML_TO_PDF}`;
      const body1 = {
        filename: hearingCode + "PMAFile",
        htmlInput: htmltopdf,
        casename: hearingData ? hearingData.caseName : "",
      };
      axios
        .post(htmltopdfurl, body1, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((res) => {
          if (res.status === 200) {
            const file = dataURLtoFile(
              "data:application/pdf;base64," + res.data,
              pdfname + ".pdf"
            );
            const url = INSERT_FILE;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", pdfname + ".pdf");
            const config = {
              headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${userToken}`,
              },
            };
            axios
              .post(url, formData, config)
              .then((response) => {
                if (res.status === 200) {
                  const url_GetFilewithSAS = `${GET_FILEWITHSAS}?filename=${
                    pdfname + ".pdf"
                  }`;
                  axios
                    .get(url_GetFilewithSAS, {
                      headers: {
                        Authorization: `Bearer ${userToken}`,
                      },
                    })
                    .then((res1) => {
                      if (res.status === 200) {
                        const byteString = atob(res1.data); // Add your base64-encoded byte string here

                        // Convert byte string to Uint8Array
                        const byteArray = new Uint8Array(byteString.length);
                        for (let i = 0; i < byteString.length; i++) {
                          byteArray[i] = byteString.charCodeAt(i);
                        }

                        // Convert Uint8Array to Blob
                        const blob = new Blob([byteArray], {
                          type: "application/pdf",
                        });

                        // Create a URL for the Blob
                        const url = URL.createObjectURL(blob);
                        setpdfURL(url);
                        setshowPDF(true);
                      }
                    });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const isZoomLinkEnabled = () => {
    const advancedhearingDate = new Date(
      caseDetails["hearingStartTimeUTCFromCMS"]
    );
    advancedhearingDate.setHours(advancedhearingDate.getHours() - 1);
    const tomorrow = new Date(advancedhearingDate);
    tomorrow.setDate(advancedhearingDate.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    const currentdatetime = new Date();
    return currentdatetime >= advancedhearingDate && currentdatetime <= tomorrow
      ? true
      : false;
  };

  const hasMassFile = props.subGroupId && props.subGroupId !== "";

  return (
    <>
      <div
        className="casesContainer Schdule"
        style={{ marginTop: "2rem", height: "100%" }}
      >
        <div
          className="row"
          style={{ height: "100%" }}
        >
          <div
            className="col-md-8 col-xs-12 pdfDiv"
            style={{ height: "100%" }}
          >
            {/* < Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js" >
                            <div style={{ height: "720px" }}>
                                <Viewer  pdfURL={demoFile} plugins={[defaultLayoutPluginInstance]} />
                            </div>
                        </Worker > */}
            {showPDF && pdfURL != "" ? (
              // <ReportViewer
              //     reportUrl={pdfURL}
              //     width="100%"
              //     height="90%"
              //     style={{
              //         border: "none",
              //         top: "0px",
              //         width: "100%",
              //         height: "80vh",
              //     }}
              //     runat="server"
              // />
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.2.228/build/pdf.worker.min.js">
                <div style={{ height: "720px" }}>
                  <Viewer
                    fileUrl={pdfURL}
                    plugins={[defaultInstance]}
                  />
                </div>
              </Worker>
            ) : (
              ""
            )}
            {/* Always showing PDF */}
            {/* {showDiv ? <div><h1>Schedule will appear 48 hours before the hearing date.</h1></div> : ""} */}
          </div>
          <div className="col-md-4 col-xs-12">
            <div className="row">
              <div
                className="font-weight-bold float-start text-start col-12"
                style={{ padding: "0" }}
              >
                Case Details
              </div>
              <div className="mb-1  row">
                <label
                  htmlFor="caseName"
                  className=" float-start text-start"
                  style={{
                    color: "#145187",
                    fontWeight: "700",
                    fontSize: "13px",
                    marginTop: "10px",
                    paddingBottom: ".75rem",
                    paddingLeft: "0",
                  }}
                >
                  {caseDetails["caseName"]}
                </label>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  {hasMassFile ? "Subgroup ID" : "NAM ID"}
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={
                      hasMassFile
                        ? props.subGroupId
                        : props.caseDetails["caseReference"]
                    }
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  Case Method
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={caseDetails["caseType"]}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  Case Status
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={caseDetails["caseStatus"]}
                  />
                </div>
              </div>
              {caseDetails["caseType"] !== null &&
                caseDetails["caseType"] !== undefined &&
                caseDetails["caseType"].includes("Arbitration") &&
                caseDetails["caseMethod"] !== null &&
                caseDetails["caseMethod"] !== undefined &&
                caseDetails["caseMethod"] !== "" && (
                  <>
                    {" "}
                    <div className="mb-1 row">
                      <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                        Case Type
                      </label>
                      <div className="col-md-8 col-12 pad0">
                        <input
                          type="text"
                          readOnly
                          className="form-control-plaintext rightt"
                          value={caseDetails["caseMethod"]}
                        />
                      </div>
                    </div>
                  </>
                )}
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  Billing Type
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={caseDetails["billingType"]}
                  />
                </div>
              </div>
              <hr
                style={{
                  marginLeft: "-1px",
                  marginRight: "-10px",
                  width: "91%",
                }}
                className="hr hr-blurry"
              />
              <div
                className="font-weight-bold float-start text-start col-12"
                style={{ padding: "0", marginTop: "0.1rem" }}
              >
                Hearing Details
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  Conference Date
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={Utility.getdate(
                      caseDetails["hearingStartTimeUTCFromCMS"]
                    )}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  Location
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={caseDetails["hearingLocation"]}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                  Status
                </label>
                <div className="col-md-8 col-12 pad0">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={caseDetails["hearingStatus"]}
                  />
                </div>
              </div>

              {props.cotinuedFrom && props.cotinuedFrom !== "-" && (
                <div className="mb-1 row">
                  <label
                    style={{ color: "red" }}
                    className="col-md-4 col-12 col-form-label float-start text-start leftt"
                  >
                    [Continuance]
                  </label>
                  <div
                    style={{ color: "red" }}
                    className="col-md-8 col-12 pad0"
                  >
                    <input
                      style={{ color: "red" }}
                      type="text"
                      readOnly
                      className="form-control-plaintext rightt"
                      value={Utility.getdate(props.cotinuedFrom)}
                    />
                  </div>
                </div>
              )}
              {props.caseDetails["isCommercialCase"] &&
                !Utility.IsHearingOfficer() && (
                  <div className="mb-1 row">
                    <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                      Type
                    </label>
                    <div className="col-md-8 col-12 pad0">
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext rightt"
                        value="Monthly Billing"
                      />
                    </div>
                  </div>
                )}
              {Utility.getComapreDate(
                Utility.getdate(caseDetails["hearingDate"]),
                Utility.getdate(new Date())
              ) && (
                <>
                  <div className="mb-1 row">
                    <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                      Paid
                    </label>
                    <div className="col-md-8 col-12 pad0">
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext rightt"
                        value={caseDetails["isPaid"] ? "Yes" : "No"}
                      />
                    </div>
                  </div>
                  <div className="mb-1 row">
                    <label className="col-md-4 col-12 col-form-label float-start text-start leftt">
                      Amount
                    </label>
                    <div className="col-md-8 col-12 pad0">
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext rightt"
                        value={
                          caseDetails["feesPaid"] !== undefined &&
                          caseDetails["feesPaid"] !== null
                            ? caseDetails["feesPaid"]
                            : "--"
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <hr
              style={{ marginLeft: "-10px" }}
              className="hr hr-blurry"
            />
            <div className="row">
              <div
                className="font-weight-bold float-start text-start col-12"
                style={{ padding: "0" }}
              >
                Meeting Details
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Start Time
                </label>
                <div className="col-md-8 col-8">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={
                      caseDetails["hearingStartTimeUTCFromCMS"]
                        ? new Date(caseDetails["hearingStartTimeUTCFromCMS"])
                            .toLocaleTimeString()
                            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3") +
                          "  (" +
                          Utility.userSpecificTimeZone() +
                          ")"
                        : //Utility.ConvertDate(caseDetails["hearingStartTime"])
                          ""
                    }
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  End Time
                </label>
                <div className="col-md-8 col-8">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={
                      caseDetails["hearingEndTimeUTCFromCMS"]
                        ? new Date(caseDetails["hearingEndTimeUTCFromCMS"])
                            .toLocaleTimeString()
                            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3") +
                          "  (" +
                          Utility.userSpecificTimeZone() +
                          ")"
                        : //Utility.ConvertDate(caseDetails["hearingEndTime"])
                          ""
                    }
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Meeting Type
                </label>
                <div className="col-md-8 col-8">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={caseDetails["hearingType"]}
                  />
                </div>
              </div>
              {caseDetails["hearingType"] === "VIDEO-CONF" && (
                <React.Fragment>
                  <a
                    href={caseDetails["meetingURL"]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={
                      isZoomLinkEnabled()
                        ? " btn btn-primary btn-medium float-start col-12 schdulebtn"
                        : "disabled-link btn btn-primary btn-medium float-start col-12 schdulebtn"
                    }
                    role="button"
                    aria-disabled="true"
                  >
                    {Utility.IsHearingOfficer()
                      ? "Start Zoom Meeting"
                      : "Join Zoom Meeting"}
                  </a>
                </React.Fragment>
              )}
            </div>
            {/* New Section */}
            <hr
              style={{ marginLeft: "-10px" }}
              className="hr hr-blurry"
            />
            <div className="row">
              <div
                className="font-weight-bold float-start text-start col-12"
                style={{ padding: "0" }}
              >
                Zoom Details
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Meeting Id
                </label>
                <div className="col-md-8 col-8">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={zoomdetails.meeting_id}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Meeting Password
                </label>
                <div className="col-md-8 col-8">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={zoomdetails.password}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Host Key
                </label>
                <div className="col-md-8 col-8">
                  <input
                    type="text"
                    readOnly
                    className="form-control-plaintext rightt"
                    value={zoomdetails.host_key}
                  />
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Dial-In
                </label>
                <div className="col-md-8 col-8">
                  <label
                    className="form-control-plaintext rightt"
                    style={{ textAlign: "left" }}
                  >
                    +1 646 558 8656 US (New York)
                    <br />
                    +1 720 707 2699 US
                  </label>
                </div>
              </div>
              <div className="mb-1 row">
                <label className="col-md-4 col-4 col-form-label float-start text-start leftt">
                  Attendees
                </label>
                <div className="col-md-8 col-8">
                  <span
                    className="form-control-plaintext rightt"
                    style={{ textAlign: "left" }}
                  >
                    {attendies}
                    {/* {caseDetails.parties!=null && caseDetails.parties!==undefined &&
                                        caseDetails.parties.map((party,index) => (
                                            
                                            <>{party?.partyType?.trim()==="PLAINTIFF" ?
                                            <p>Plaintiff Attorney:&nbsp;&nbsp; {party?.partyName}&nbsp;&nbsp;({party?.firmName?.trim()})</p>
                                            
                                            :<p>{party?.partyType?.trim()!=="PLAINTIFF" && party.firmType!=null && party?.firmType!=undefined && party?.firmType?.trim()==="ATTORNEY"? "Attorney": "Adjuster"} for {party?.firmName!=null && party?.firmName!=undefined?party?.firmName?.trim():''}:&nbsp;&nbsp;{party?.partyName}</p>
                                            }
                                            {party.partyReference!==null && party?.partyReference?.trim()!=='' &&<><p>Claim Number:&nbsp;&nbsp; {party.partyReference} <br/></p></>}
                                            {party.counselCode!==null && party.counselCode!=='' && party.counselCode>0 &&<><p>Attorney for {party.firmName}:&nbsp;&nbsp;
                                            {party?.partyType?.trim()==="PLAINTIFF" && <>{party.counselFirst} {party.counselLast} {party.counselSuffix}</>}
                                            {party?.partyType?.trim()!=="PLAINTIFF" && <>{party.counselFirst} {party.counselLast} {party.counselSuffix}&nbsp;({party.counselFirmName})</>}<br/></p></>}
                                            </>
                                        ))
                                    } */}
                  </span>
                </div>
              </div>
              <br />
              <br />
              <div className="mb-1 row">
                <span className="form-control-plaintext rightt">
                  If you are having difficulties or need assistance joining your
                  hearing, please contact NAM's Helpdesk for assistance. Phone:
                  (516) 941-3211 Email: helpdesk@namadr.com
                </span>
              </div>
            </div>
            {/* New Section End */}
          </div>
          <Divider />
        </div>
        <div style={{ display: "none" }}>
          <SchedulingTemplate
            callBackForAttenddiesSchedular={callBackForAttenddiesSchedular}
            callBackForZoomDetails={callBackForZoomDetails}
            caseDetails={caseDetails}
            hearingCode={hearingCode}
            pmaDetails={pmaDetails}
            cotinuedFrom={props.cotinuedFrom}
          />
        </div>
        <div style={{ display: "none" }}>
          <SchedulingTemplateAfter48hrs caseDetails={caseDetails} />
        </div>
      </div>
    </>
  );
}
