import AlertDialog from "../../shared/AlertDialog";
import React, { createRef } from "react";
import "./login.scss";
import "../../App.css";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-i18next";
import { post, get } from "../../api/api";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CryptoJS from "crypto-js";
import Loading from "../../assets/img/loader.gif";
import Signin from "f1-signin-form-react";
import * as Utility from "../../utils/utility";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

import {
  HEARINGOFFICER_BYEMAIL,
  UPDATE_LOGGEDIN_DETAILS,
  GENERATE_TOKEN,
  USER_ROLES,
  CLIENT_ID,
  isSyncBack,
  LOGIN_SYNC,
  GET_PENDINGCOUNTFORNEUTRAL,
  HEARINGOFFICER_BYID,
  VERIFY_USER_PERMISSIONS,
} from "../../api/baseURL";
import namlogo from "../../assets/img/namlogo.png";
import ContactUs from "../contacts/ContactUs";
import { Button } from "react-bootstrap";
import { decryptItem, encryptItem } from "../../shared/Encrypt";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      customRef: createRef(),
      show: false,
      email: "",
      password: "",
      isdisplayAlertError: false,
      current: { id: 1, email: "", password: "" },
      isLoaded: true,
      showContactModal: false,
      extErrors: {
        passwordLengthMin: 6,
        passwordLengthMax: 10,
        username: "Username is required",
        usernameContainWhiteSpace: "Username should not have white spaces",
        email: "email address is required",
        password: "Password is required",
        passwordContainWhiteSpace: "Password should not have white spaces",
        passwordLength: "Password's length must between 6 to 10",
      },

      formData: [
        {
          key: "email",
          type: "text",
          disabled: false,
          id: "username",
          icon: "user",
          label: "User Name",
          validators: [
            {
              name: "required",
              value: true,
              validationMessage: "User name is required",
            },
          ],
        },
        {
          key: "password",
          type: "password",
          disabled: false,
          id: "password",
          icon: "user",
          label: "Password",
          validators: [
            { name: "required", value: true },
            { name: "minLength", value: 2 },
            {
              name: "maxLength",
              value: 10,
              validationMessage: "Maximum 10 digits allowed.",
            },
          ],
        },
      ],
    };
  }
  cancelPreview = () => {
    this.setState({ showContactModal: false });
  };
  submitForm = (event, data) => {
    const secKey = "b14ca5898a4e4133bbce2ea2315a1916";
    event.preventDefault();
    this.setState({ isLoaded: false });

    if (localStorage.getItem("rememberMe")) {
      localStorage.setItem("username", data.formData.username);
    }

    post(GENERATE_TOKEN, {
      email: data.formData.email,
      username: data.formData.username,
      password: data.formData.password,
      clientID: CLIENT_ID,
    })
      .then((res) => {
        if (res.status === 200) {
          // Save the token and user details
          localStorage.setItem("showRez", 20);
          localStorage.setItem("token", res.data.tokenGeneration.accessToken);
          localStorage.setItem(
            "refreshToken",
            res.data.tokenGeneration.refreshToken
          );
          localStorage.setItem(
            "email",
            CryptoJS.AES.encrypt(
              JSON.stringify(res.data.email),
              secKey
            ).toString()
          );

          encryptItem("userid", res.data.id);
          encryptItem("usercode", res.data.userCode);
          encryptItem("jCode", res.data.jCode);
          encryptItem("neutralsEmail", res.data.email);
          localStorage.setItem("signinAttempt", res.data.signinAttempt);
          localStorage.setItem("hearingOfficerName", `${res.data.userName}`);
          localStorage.setItem(
            "fname",
            `${
              res.data.userName !== null ? res.data.userName.split(" ")[0] : ""
            }`
          );
          localStorage.setItem("name", `${res.data.userName}`);
          this.getIP(res.data.tokenGeneration.accessToken);

          // Fetch user permissions
          get(`${VERIFY_USER_PERMISSIONS}${res.data.email}`)
            .then((permissionsRes) => {
              localStorage.setItem(
                "permissions",
                JSON.stringify(permissionsRes.data)
              );
              // Fetch user roles
              return get(USER_ROLES + res.data.email);
            })
            .then((resp) => {
              localStorage.setItem("role", resp.data.name);
              encryptItem("roleId", resp.data.id);

              // Fetch hearing officer or email details based on role
              const endpoint =
                resp.data.name === "Hearing Officer"
                  ? `${HEARINGOFFICER_BYID}?Id=${res.data.jCode}`
                  : `${HEARINGOFFICER_BYEMAIL}?email=${res.data.email}`;
              return get(endpoint, {
                headers: {
                  Authorization: `Bearer ${res.data.tokenGeneration.accessToken}`,
                },
              });
            })
            .then((resp1) => {
              if (Utility.IsHearingOfficer()) {
                if (isSyncBack) {
                  const body = {
                    userCode: res.data.jCode,
                    lastUpdate: new Date().toLocaleString("en-US", {
                      timeZone: "America/New_York",
                    }),
                  };
                  const config = {
                    headers: {
                      "content-type": "multipart/form-data",
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  };
                }
                encryptItem("neutralsEmail", resp1.data[0].hearingOfficerEmail);
                return get(
                  `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${resp1.data[0].hearingOfficerEmail}&userCode=${resp1.data[0].hearingOfficerCode}`,
                  {
                    headers: {
                      Authorization: `Bearer ${res.data.tokenGeneration.accessToken}`,
                    },
                  }
                );
              } else {
                this.props.history.push("/cases");
                return Promise.resolve(); // Exit chain for non-hearing officers
              }
            })
            .then((resp2) => {
              if (resp2) {
                console.log("pendingTaskCount", resp2);
                localStorage.setItem("pendingTaskCount", `${resp2.data}`);
              }
              this.props.history.push("/cases");
              this.setState({ isLoaded: true });
            })
            .catch((error) => {
              console.error("Error during processing:", error);
              this.setState({ show: true, isLoaded: true });
            });
        }
      })
      .catch((error) => {
        console.error("Error during token generation:", error);
        this.setState({ show: true, isLoaded: true });
      });
  };

  rememberMe = (value) => {
    localStorage.setItem("rememberMe", value.formData.rememberme);
    if (value.formData.rememberme) {
      var result = new Date();
      result.setDate(result.getDate() + 5);
      localStorage.setItem("expiresAt", result);
    }
  };
  getIP = async (token) => {
    const res = await axios.get("https://geolocation-db.com/json/");

    const body = {
      iP: res.data.IPv4,
      userCode: decryptItem("usercode"),
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(UPDATE_LOGGEDIN_DETAILS, body, config)
      .then((res2) => {
        if (res2.status === 200) {
          if (isSyncBack) {
            axios
              .post(LOGIN_SYNC, body, config)
              .then((res3) => {
                if (res3.status === 200) {
                  // TO DO document why this block is empty
                }
              })
              .catch((error) => {
                this.setState({ isdisplayAlertError: true });
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        this.setState({ isdisplayAlertError: true });
        console.log(error);
      });
  };
  forgotPass = (e) => {
    e.preventDefault();
    this.props.history.push("/forgotPassword");
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ show: false });
  };

  componentDidMount() {
    localStorage.clear();
    const email = document.getElementById("username");
    const password = document.getElementById("password");
    password.setAttribute("autocomplete", "off");
    const rememberme = document.getElementsByName("rememberme")[0];
    document.getElementsByName("rememberme")[0].nextSibling.innerHTML =
      "Remember Username";
    if (localStorage.getItem("rememberMe")) {
      rememberme.checked = localStorage.getItem("rememberMe");
      email.value = localStorage.getItem("username");
    }
    //setInterval(() => {
    if (this.state.isLoaded) {
      if (
        email !== null &&
        email.value &&
        password !== null &&
        password.value
      ) {
        try {
          const button = document.getElementsByClassName(
            "btn-indigo btn Ripple-parent disabled btn btn-primary"
          )[0];
          if (button !== undefined) {
            button.setAttribute(
              "class",
              "btn-indigo btn Ripple-parent btn btn-primary"
            );
            button.removeAttribute("disabled");
          }
        } catch (e) {}
      } else {
        const button = document.getElementsByClassName(
          "btn-indigo btn Ripple-parent btn btn-primary"
        )[0];
        button.setAttribute(
          "class",
          "btn-indigo btn Ripple-parent btn btn-primary"
        );
        button.removeAttribute("disabled");
        //button.setAttribute("disabled", "");
      }
    }
    //}, 100);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.isLoaded ? (
          <div className="main-container loginPage errorInvalid">
            <Grid container>
              <Grid item sm={12} md={12} className="loginBox ">
                <Snackbar
                  open={this.state.show}
                  autoHideDuration={5000}
                  onClose={() =>
                    this.handleClose({
                      vertical: "bottom",
                      horizontal: "right",
                    })
                  }
                >
                  <Alert severity="error">
                    You have entered an incorrect username or password. Please
                    try again.
                  </Alert>
                </Snackbar>

                <div className="SigninContainer">
                  <Paper className={""} style={{ textAlign: "left" }}>
                    <div className="loginheadbox">
                      <div>
                        <img src={namlogo} />
                      </div>

                      <p className="font-weight-bold">
                        Welcome to the Neutrals Portal
                      </p>
                    </div>
                    <Signin
                      rememberMeText="Remember Username"
                      className="login-form"
                      Email={false}
                      UserName={true}
                      RememberMe={true}
                      ForgotPassword={true}
                      titleAlign="left"
                      LableEmail={t("Email")}
                      PlaceholderEmail={t("name@domain.com")}
                      LablePassword={t("Password")}
                      showPasswordInput={true}
                      showPassword={true}
                      PlaceholderPassword={t("Password")}
                      SubmitButtonText={t("Log In")}
                      ForgotPasswordText={t("Forgot your password?")}
                      LableUsername={t("Username")}
                      PlaceholderUsername={t("Enter your username")}
                      onSubmit={this.submitForm}
                      onChange={this.rememberMe}
                      onClick={this.forgotPass}
                      disableSubmit={true}
                      design="bootstrap"
                      materialOutline={true}
                      extErrors={this.state.extErrors}

                      // validationEvent="blur"
                    />

                    <div className="container footer">
                      <p>Need Help?</p>
                      <p className="">
                        <a
                          onClick={() =>
                            this.setState({ showContactModal: true })
                          }
                          href="#"
                        >
                          Contact Us
                        </a>
                      </p>{" "}
                    </div>
                  </Paper>
                </div>
              </Grid>
            </Grid>
            {this.state.showContactModal && (
              <Modal
                show={this.state.showContactModal}
                className="decmodal-content"
                contentClassName="modal-classContent center"
                onClose={this.cancelPreview}
                onHide={this.cancelPreview}
              >
                <Modal.Header>
                  <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ zIndex: 99999999 }}>
                  <ContactUs />
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.cancelPreview}>Close</Button>
                </Modal.Footer>
              </Modal>
            )}
            {this.state.isdisplayAlertError === true && (
              <AlertDialog
                isdisplayAlert={false}
                isdisplayAlertError={this.state.isdisplayAlertError}
                updatedisplayAlertError={this.updatedisplayAlert}
              ></AlertDialog>
            )}
          </div>
        ) : (
          <div className="LoadingData">
            <iframe
              src={Loading}
              width="100%"
              allowtransparency="true"
              frameBorder="0"
              marginWidth="0"
              marginHeight="0"
              scrolling="no"
            ></iframe>

            <h4>Loading...</h4>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(Login);
