import React, { useState, useEffect } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./cases.scss";
import { HEARING_DETAILS } from "../../api/baseURL";
import axios from "axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SchedulingInfo from "./SchedulingInfo";
import Timesheet from "./timesheet";
import PMA from "./pma";
import DemoGraphics from "../demographics/demoGraphics";
import Loading from "../../assets/img/loader.gif";
import Decision from "../cases/decision";
import * as Utility from "../../utils/utility";
import CommercialTimesheetGrid from "./commercialTimesheetGrid";
import { decryptItem } from "../../shared/Encrypt";
import CaseInfo from "./CaseInfo";
import { useDispatch } from "react-redux";
import { setSelectedCaseForDecisionApply } from "../../actions/caseAction";
import {
  hasPermissionScreen,
  hasReadPermissionScreen,
} from "../permission/PermissionProvider";

export default function CaseDetails(props) {
  const dispatch = useDispatch();
  const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
  const hearingCode = parseInt(hearingDetails.hearingCode);
  const caseType = hearingDetails.caseType; ////"arbitration";
  let tabpendingAt = hearingDetails.pendingAt;
  const goBack = hearingDetails.goBack;
  const timesheetUrl = `${window.location.href}`;
  const pmaUrl = `${window.location.href}`;
  const decisionUrl = `${window.location.href}`;

  useEffect(() => {
    getHearingDetails();
  }, []);

  const getInitialActiveTab = () => {
    if (
      hearingDetails?.rowData?.massFileId &&
      hearingDetails?.rowData?.massFileId !== ""
    ) {
      return "caseInfo";
    }

    if (!hearingDetails?.rowData?.massFileId && tabpendingAt === "caseInfo") {
      return "schedulingInformation";
    }

    if (tabpendingAt === "review") {
      return "timesheets";
    }

    return tabpendingAt;
  };

  const [caseDetails, setcaseDetails] = useState({});
  const [isLoaded, setisLoaded] = useState(false);
  const [showTimeSheet, setshowTimeSheet] = useState(true);
  const [attendies, setAttendies] = useState("");
  const [activeTab, setActiveTab] = useState(getInitialActiveTab());
  const [hearingData, setHearingData] = useState({});
  const callBackForAttenddies = (attenddies) => {
    setAttendies(attenddies);
  };

  const callBackreloadcasedetails = () => {
    console.log("callBackreloadcasedetails");
  };
  const getHearingDetails = () => {
    const neutralEmail = decryptItem("neutralsEmail");

    const url = `${HEARING_DETAILS}?hearingCode=${hearingCode}&neutralEmail=${neutralEmail}
        &userId=${
          Utility.IsHearingOfficer()
            ? decryptItem("jCode")
            : decryptItem("userid")
        }&roleId=${decryptItem("roleId")}`;
    axios
      .get(`${url}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        ////start timezone change request
        let utcTimeSheets = [];
        setHearingData(res?.data);
        if (
          res?.data &&
          Object.keys(res?.data).length > 0 &&
          res.data.timeSheets
        ) {
          res.data.timeSheets.forEach((element) => {
            element.hearingStartTime =
              element.hearingStartTimeUTC &&
              (element.hearingStartTimeUTC.indexOf("GMT")
                ? Utility.convertUTCDateToUserTimeZone(
                    element.hearingStartTimeUTC
                  )
                : Utility.convertUTCDateToUserTimeZone(
                    new Date(element.hearingStartTimeUTC).toUTCString()
                  ));

            element.hearingEndTime =
              element.hearingEndTimeUTC &&
              (element.hearingEndTimeUTC.indexOf("GMT")
                ? Utility.convertUTCDateToUserTimeZone(
                    element.hearingEndTimeUTC
                  )
                : Utility.convertUTCDateToUserTimeZone(
                    new Date(element.hearingEndTimeUTC).toUTCString()
                  ));
            utcTimeSheets.push(element);
          });
        }
        if (res.data.timeSheets && res.data.timeSheets.length > 0) {
          res.data.timeSheets = utcTimeSheets;
          res.data.hearingStartTime = utcTimeSheets[0].hearingStartTime;
          res.data.hearingEndTime = utcTimeSheets[0].hearingEndTime;
          res.data.hearingDate = utcTimeSheets[0].hearingStartTime;
        } else {
          res.data.hearingStartTime = Utility.convertUTCDateToUserTimeZone(
            new Date(res.data.hearingStartTimeUTCFromCMS).toUTCString()
          );
          res.data.hearingEndTime = Utility.convertUTCDateToUserTimeZone(
            new Date(res.data.hearingEndTimeUTCFromCMS).toUTCString()
          );
        }
        ////End timezone change request
        setcaseDetails(res.data);
        setisLoaded(true);
        setshowTimeSheet(
          res.data.isCommercialCase !== null &&
            res.data.isCommercialCase !== undefined
            ? !res.data.isCommercialCase
            : true
        );
      })
      .catch(() => {
        setisLoaded(true);
      });
  };

  const isPMAorDecisionSubmited = () => {
    setcaseDetails({ ...caseDetails, isDecisionSubmitted: true });
  };
  const displayTimesheet = (e, data) => {
    console.log("display", e, data);
  };
  const [timeDurationSetOnDecision, setTimeDurationSetOnDecision] =
    useState("");

  const timeDurationOnDecision = (val) => {
    setTimeDurationSetOnDecision(val);
  };

  const onTabChange = (key) => {
    if (activeTab === key) {
      return;
    }

    if (key === "decision") {
      dispatch(setSelectedCaseForDecisionApply(null));
    }

    setActiveTab(key);
  };

  return (
    <div className="casesContainer">
      {isLoaded ? (
        <>
          <div className="breadHeader">
            <ul className="breadcrumb">
              <li style={{ color: "#3E3E3E" }}>
                <a
                  href={`#${
                    goBack !== undefined && goBack !== null
                      ? goBack === "Calendar"
                        ? "CaseCalendar"
                        : goBack
                      : "cases"
                  }`}
                >
                  {goBack !== undefined && goBack !== null
                    ? goBack === "cases"
                      ? "Hearings"
                      : goBack.toLowerCase() === "pendingtasks"
                      ? "Pending Tasks"
                      : goBack
                    : "Hearings"}
                </a>
              </li>
              <li className="disabled-icon">{caseDetails["caseName"]}</li>
            </ul>
            <div className="stickyDiv">
              <Tabs
                defaultActiveKey={
                  activeTab === "pma" && !caseType.includes("Mediation")
                    ? "decision"
                    : activeTab
                }
                id="casedetailtabs"
                className="mb-3"
                fill
                activeKey={activeTab}
                onSelect={(k) => onTabChange(k)}
              >
                {hearingDetails?.rowData?.massFileId &&
                  hearingDetails?.rowData.massFileId !== "" && (
                    <Tab eventKey="caseInfo" title="Case Information">
                      <CaseInfo
                        caseDetails={caseDetails}
                        hearingDetails={hearingDetails?.rowData}
                        changeTab={setActiveTab}
                      />
                    </Tab>
                  )}
                <Tab
                  eventKey="schedulingInformation"
                  title=" Scheduling Information"
                >
                  <SchedulingInfo
                    callBackForAttenddies={callBackForAttenddies}
                    hearingCode={hearingCode}
                    caseDetails={caseDetails}
                    cotinuedFrom={hearingDetails.cotinuedFrom}
                    subGroupId={hearingDetails?.rowData?.subGroupId}
                  />
                </Tab>
                {showTimeSheet &&
                  hasPermissionScreen("Hearings", "Time Sheet") && (
                    <Tab
                      eventKey="timesheets"
                      title="Timesheet"
                      disabled={hasReadPermissionScreen(
                        "Hearings",
                        "Time Sheet"
                      )}
                    >
                      <Timesheet
                        hearingCode={hearingCode}
                        caseDetails={caseDetails}
                        timesheetUrl={timesheetUrl}
                        hearingDetails={hearingDetails}
                        timeDurationSetOnDecision={timeDurationSetOnDecision}
                        callBackreloadcasedetails={callBackreloadcasedetails}
                      />
                    </Tab>
                  )}
                {caseDetails.isCommercialCase &&
                hasPermissionScreen("Hearings", "Time Sheet") ? (
                  <Tab
                    eventKey="timesheets"
                    title="Timesheet"
                    disabled={hasReadPermissionScreen("Hearings", "Time Sheet")}
                  >
                    <CommercialTimesheetGrid
                      hearingCode={hearingCode}
                      caseDetails={caseDetails}
                      displayTimesheet={displayTimesheet}
                    />
                  </Tab>
                ) : (
                  ""
                )}
                {caseType.trim().toLowerCase().includes("mediation") &&
                hasPermissionScreen("Hearings", "PMA / Decision Form") ? (
                  <Tab
                    eventKey="pma"
                    title="PMA"
                    disabled={hasReadPermissionScreen(
                      "Hearings",
                      "PMA / Decision Form"
                    )}
                  >
                    <PMA
                      hearingCode={hearingCode}
                      caseDetails={caseDetails}
                      isPMAorDecisionSubmited={isPMAorDecisionSubmited}
                      pmaUrl={pmaUrl}
                      timeDurationOnDecision={timeDurationOnDecision}
                      callBackreloadcasedetails={callBackreloadcasedetails}
                    />
                  </Tab>
                ) : caseType.trim().toLowerCase().includes("arbitration") &&
                  hasPermissionScreen("Hearings", "PMA / Decision Form") ? (
                  <Tab
                    eventKey="decision"
                    title="Decision"
                    disabled={hasReadPermissionScreen(
                      "Hearings",
                      "PMA / Decision Form"
                    )}
                  >
                    <Decision
                      attendies={attendies}
                      hearingCode={hearingCode}
                      caseDetails={caseDetails}
                      decisionUrl={decisionUrl}
                      hearingDetails={hearingDetails}
                      timeDurationOnDecision={timeDurationOnDecision}
                      callBackreloadcasedetails={callBackreloadcasedetails}
                    />
                  </Tab>
                ) : (
                  hasPermissionScreen("Hearings", "PMA / Decision Form") && (
                    <Tab
                      eventKey="decision"
                      title="Decision"
                      disabled={hasReadPermissionScreen(
                        "Hearings",
                        "PMA / Decision Form"
                      )}
                    >
                      <Decision
                        attendies={attendies}
                        hearingCode={hearingCode}
                        caseDetails={caseDetails}
                        decisionUrl={decisionUrl}
                        hearingDetails={hearingDetails}
                        timeDurationOnDecision={timeDurationOnDecision}
                        callBackreloadcasedetails={callBackreloadcasedetails}
                      />
                    </Tab>
                  )
                )}
                {hasPermissionScreen("Hearings", "Demographics Form") && (
                  <Tab
                    eventKey="demographics"
                    title="Demographics"
                    disabled={hasReadPermissionScreen(
                      "Hearings",
                      "Demographics Form"
                    )}
                  >
                    <DemoGraphics
                      hearingCode={hearingCode}
                      caseDetails={caseDetails}
                      callBackreloadcasedetails={callBackreloadcasedetails}
                      hearingData={hearingData}
                    />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </>
      ) : (
        <div className="LoadingData">
          <iframe
            title="loading"
            src={Loading}
            width="100%"
            allowtransparency="true"
            frameBorder="0"
            marginWidth="0"
            marginHeight="0"
            scrolling="no"
          ></iframe>
          <h4>Loading...</h4>
        </div>
      )}
    </div>
  );
}
